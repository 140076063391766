import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonSharedModule } from '../shared/common-shared.module';
import { WidgetRegistryService } from '../widget-registry/widget-registry';
import { AlarmCounterColumnComponent } from './alarm-counter-column/alarm-counter-column.component';
import { AlarmCounterComponent } from './alarm-counter-column/alarm-counter/alarm-counter.component';
import { AlarmSeverityCounterComponent } from './alarm-counter-column/alarm-counter/alarm-severity-counter/alarm-severity-counter.component';
import { AlarmSeverityColumnComponent } from './alarm-severity-column/alarm-severity-column.component';
import { BooleanColumnCellComponent } from './boolean-column/boolean-column-cell/boolean-column-cell.component';
import { BooleanColumnComponent } from './boolean-column/boolean-column-component/boolean-column.component';
import { BooleanColumnFilterComponent } from './boolean-column/boolean-column-filter/boolean-column-filter.component';
import { ButtonColumnHelperComponent } from './button-column/button-column-helper/button-column-helper.component';
import { ButtonColumnComponent } from './button-column/button-column.component';
import { ColumnHeaderTemplateComponent } from './column-header-template/column-header-template.component';
import { ColumnsContainerComponent } from './columns-container/columns-container.component';
import { CurrencyColumnComponent } from './currency-column/currency-column.component';
import { DateDurationColumnValueComponent } from './date-duration-column/date-duration-column-value/date-duration-column-value.component';
import { DateDurationColumnComponent } from './date-duration-column/date-duration-column.component';
import { DateTimeColumnComponent } from './date-time-column-component/date-time-column.component';
import { DynamicGridComponent } from './dynamic-grid/dynamic-grid.component';
import { EstimatedEditedCellMarkDirective } from './edit-templates/estimated-edited-cell-mark.directive';
import { UomEditTemplateComponent } from './edit-templates/uom-edit-template/uom-edit-template.component';
import { EligibilityColumnComponent } from './eligibility-column/eligibility-column.component';
import { EnumerableColumnComponent } from './enumerable-column/enumerable-column-component/enumerable-column.component';
import { EnumerableColumnFilterComponent } from './enumerable-column/enumerable-column-filter/enumerable-column-filter.component';
import { GenericGridComponent } from './generic-grid/generic-grid.component';
import { GridDateTimeFilterComponent } from './grid-date-time-filter/grid-date-time-filter.component';
import { GridHeaderButtonsComponent } from './grid-header-buttons/grid-header-buttons.component';
import { GridInThePastInfoComponent } from './grid-in-the-past-info/grid-in-the-past-info.component';
import { HistoricalAlarmsGridComponent } from './historical-alarms/historical-alarms-grid.component';
import { HistoricalAlarmsGridService } from './historical-alarms/historical-alarms-grid.service';
import { ImageColumnCellComponent } from './image-column/image-column-cell/image-column-cell.component';
import { ImageColumnComponent } from './image-column/image-column-component/image-column.component';
import { ImageColumnFilterComponent } from './image-column/image-column-filter/image-column-filter.component';
import { LocalGridComponent } from './local-grid/local-grid.component';
import { MaxminColumnComponent } from './maxmin-column/maxmin-column.component';
import { NumericColumnFilterComponent } from './numeric-column-filter/numeric-column-filter.component';
import { PipeGridComponent } from './pipe-grid/pipe-grid.component';
import { ColumnTitlePipe } from './pipes/column-title.pipe';
import { ComparisonImageIconColor } from './pipes/comparison-image-icon-color.pipe';
import { ComparisonImageIconName } from './pipes/comparison-image-icon-name.pipe';
import { ComparisonPercentage } from './pipes/comparison-percentage.pipe';
import { ComparisonVisibility } from './pipes/comparison-visibility.pipe';
import { EnumFieldPipe } from './pipes/enum-field.pipe';
import { GridCellEmptyPipe } from './pipes/grid-cell-empty.pipe';
import { ImageIconColorPipe } from './pipes/image-icon-color.pipe';
import { ImageIconIsSvgPipe } from './pipes/image-icon-is-svg.pipe';
import { ImageIconNamePipe } from './pipes/image-icon-name.pipe';
import { LogsInterfacesPopupComponent } from './popups/logs-interfaces-popup/logs-interfaces-popup.component';
import { RangeChartComponent } from './range-chart-component/range-chart.component';
import { SelectAllColumnComponent } from './select-all-column/select-all-column.component';
import { SelectionMenuComponent } from './selection-menu/selection-menu.component';
import { SelectorColumnFilterComponent } from './selector-column-filter/selector-column-filter.component';
import { SelectorColumnComponent } from './selector-column/selector-column.component';
import { SelectorFieldPipe } from './selector-column/selector-field.pipe';
import { GridEditionService } from './services/grid-edition.service';
import { TextColumnFilterComponent } from './text-column-filter/text-column-filter.component';
import { TimeControlBarComponent } from './time-control-bar/time-control-bar.component';
import { UomColumnRoundDecimalsPipe } from './uom-column-component/uom-column-round-decimals.pipe';
import { UomColumnComponent } from './uom-column-component/uom-column.component';
import { UomColumnFilterComponent } from './uom-column-filter-component/uom-column-filter/uom-column-filter.component';
import { UomColumnHelperComponent } from './uom-column-helper/uom-column-helper.component';
import { UserColumnComponent } from './user-column/user-column.component';
import { WlmColumnComponent } from './wlm-column/wlm-column.component';

@NgModule({
  declarations: [
    WlmColumnComponent,
    GenericGridComponent,
    EligibilityColumnComponent,
    EnumerableColumnComponent,
    EnumerableColumnFilterComponent,
    BooleanColumnComponent,
    BooleanColumnFilterComponent,
    UomColumnFilterComponent,
    DateTimeColumnComponent,
    UomColumnComponent,
    UomColumnRoundDecimalsPipe,
    GridDateTimeFilterComponent,
    HistoricalAlarmsGridComponent,
    PipeGridComponent,
    CurrencyColumnComponent,
    SelectAllColumnComponent,
    ImageColumnComponent,
    ImageColumnFilterComponent,
    MaxminColumnComponent,
    AlarmCounterColumnComponent,
    AlarmCounterComponent,
    AlarmSeverityColumnComponent,
    DateDurationColumnComponent,
    DateDurationColumnValueComponent,
    UomColumnHelperComponent,
    AlarmSeverityCounterComponent,
    ColumnsContainerComponent,
    LocalGridComponent,
    SelectionMenuComponent,
    ColumnTitlePipe,
    EnumFieldPipe,
    ImageIconNamePipe,
    ImageIconIsSvgPipe,
    ImageIconColorPipe,
    SelectorFieldPipe,
    RangeChartComponent,
    NumericColumnFilterComponent,
    UserColumnComponent,
    SelectorColumnComponent,
    SelectorColumnFilterComponent,
    UomEditTemplateComponent,
    EstimatedEditedCellMarkDirective,
    DynamicGridComponent,
    ColumnHeaderTemplateComponent,
    GridHeaderButtonsComponent,
    GridCellEmptyPipe,
    TextColumnFilterComponent,
    ButtonColumnComponent,
    ButtonColumnHelperComponent,
    LogsInterfacesPopupComponent,
    ComparisonImageIconName,
    ComparisonImageIconColor,
    ComparisonPercentage,
    ComparisonVisibility,
    TimeControlBarComponent,
    GridInThePastInfoComponent,
    ImageColumnCellComponent,
    BooleanColumnCellComponent,
  ],
  imports: [CommonModule, TranslateModule, CommonSharedModule],
  exports: [
    WlmColumnComponent,
    GenericGridComponent,
    LocalGridComponent,
    EligibilityColumnComponent,
    EnumerableColumnComponent,
    EnumerableColumnFilterComponent,
    BooleanColumnComponent,
    BooleanColumnFilterComponent,
    UomColumnFilterComponent,
    DateTimeColumnComponent,
    UomColumnComponent,
    UomColumnRoundDecimalsPipe,
    GridDateTimeFilterComponent,
    HistoricalAlarmsGridComponent,
    PipeGridComponent,
    CurrencyColumnComponent,
    SelectAllColumnComponent,
    MaxminColumnComponent,
    RangeChartComponent,
    NumericColumnFilterComponent,
    DynamicGridComponent,
    ButtonColumnComponent,
    GridInThePastInfoComponent,
  ],
  providers: [
    HistoricalAlarmsGridService,
    // ActiveAlarmsGridService, // remove and keep root provider for simplicity
    GridEditionService,
    // LogsInterfacesService, // remove and keep root provider for simplicity
  ],
})
export class WLMGridModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      {
        name: 'PipeGridComponent',
        widgetClass: PipeGridComponent,
      },
    ]);
  }
}
