import { Component, EventEmitter, Input, Output } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import {
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  catchError,
  filter,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { CrudConstants } from 'src/app/common-modules/dependencies/shared/crud-constants';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { DragListCardSettings } from 'src/app/common-modules/shared/core/drag-list-card/drag-list-card-settings';
import {
  AllowDropCallbackData,
  DragListCustomSettings,
} from 'src/app/common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { DynamicRenderizerComponentService } from 'src/app/common-modules/shared/services/dynamic-renderizer-component.service';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { ICustomerClassTypeDto } from '../../features/shared/model/customer/customer-class-type.dto';
import { InformedResponse } from '../../features/shared/model/informed-response.dto';
import { AvailableSignalDto } from '../../features/shared/model/signals/available-signal.dto';
import { SmartMeterChangesInformedDto } from '../models/smart-meter-changes-informed.dto';
import { SmartMeterChangesDto } from '../models/smart-meter-changes.dto';
import { SmartMeterConfigDto } from '../models/smart-meter-config.dto';
import { SmartMeterSaveDto } from '../models/smart-meters-save.dto';
import { SmartMetersChangesService } from '../services/smart-meters-changes.service';
import { SmartMetersService } from '../services/smart-meters.service';
import { SmartMeterProcessedChanges } from '../smart-meters-changes/smart-meters-change.dto';
import { SmartMetersChangesComponent } from '../smart-meters-changes/smart-meters-changes.component';

interface CheckBeforeSaveResult {
  answer: boolean;
  changes: SmartMeterChangesDto[];
}

const COMPONENT_SELECTOR = 'wlm-smart-meters-current-config';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './smart-meters-current-config.component.html',
  styleUrl: './smart-meters-current-config.component.scss',
})
export class SmartMetersCurrentConfigComponent {
  private _selectedNE: INetworkElementDto;
  public get selectedNE(): INetworkElementDto {
    return this._selectedNE;
  }

  @Input() public set selectedNE(value: INetworkElementDto) {
    this.reset();

    if (value) {
      this._selectedNE = value;
      this.updateQueryParams();
      this.loadSignals();
    }
  }

  @Input() widgetId: string;
  @Input() pageId: string;
  @Input() disableButtons = false;
  @Input() disableInitialLoad = false;
  @Input() historicalMode = false;
  @Output() formChanges = new EventEmitter<SmartMeterSaveDto>();

  readonly T_SCOPE = `${AppModules.SmartMeters}.${COMPONENT_SELECTOR}`;

  private _configurationHasChanged = false;
  public get configurationHasChanged() {
    return this._configurationHasChanged;
  }
  public set configurationHasChanged(value) {
    this._configurationHasChanged = value;
    this.setPendingChanges(this.pageId, this.getPendingChanges(value));
  }

  reloadList$ = new Subject<void>();
  settings: DragListSettings;
  settingsCustom: DragListCustomSettings;
  cardSettings: DragListCardSettings;
  queryParams: Map<string, any>;
  excludedSignals: AvailableSignalDto[] = [];
  refreshList$ = new Subject<void>();
  isReadOnly = true;
  setLoading: (loading: boolean) => void;
  customerClassTypes: ICustomerClassTypeDto[] = [];
  configurationsHash: { [customerClassTypeId: number]: SmartMeterConfigDto } = {};
  readonly permissionsCrud = 'WLMNetworkCrud';
  private _originalConfigurationsHash: { [customerClassTypeId: string]: SmartMeterConfigDto };
  private _customerClassesReadySubs: Subscription;

  private readonly _pointIdFieldName = 'pointId';
  private readonly _pointDescriptionFieldName = 'pointDescription';
  private readonly _titleFieldName = 'title';
  private readonly _elementIdParamName = 'elementId';
  private readonly _isZoneParamName = 'isZone';
  private readonly _serviceName = 'SmartMetersService';
  private readonly _historicalSignalServiceName = 'AvailableSignalsVersionService';
  private readonly _pagesize = 50;
  private readonly _customerClassesReady$ = new ReplaySubject<void>(1);

  constructor(
    private readonly _smartMetersService: SmartMetersService,
    private readonly _dialogService: DialogService,
    private readonly _arrayHelperService: ArrayHelperService,
    private readonly _spinnerService: SpinnerService,
    private readonly _authorizeService: AuthorizeService,
    private readonly _pendingChangesService: PendingChangesManagerService,
    private readonly _globalsService: GlobalsService,
    private readonly _componentRendererService: DynamicRenderizerComponentService,
    private readonly _smartMetersChangesService: SmartMetersChangesService
  ) {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {
    this.checkReadOnlyAccess();
    this.prepareListsSettings();
    this.loadCustomerClasses();
  }

  private loadCustomerClasses(): void {
    this.setLoading(true);
    this._globalsService
      .getCustomerClassTypes()
      .pipe(finalize(() => this.setLoading(false)))
      .subscribe((customerClassTypes) => {
        this.customerClassTypes = asEnumerable(customerClassTypes)
          .OrderByDescending((cct) => cct.isMeasured)
          .ThenBy((cct) => cct.customerClassTypeName)
          .ToArray();
        this._customerClassesReady$.next();
      });
  }

  checkReadOnlyAccess(): void {
    this._authorizeService
      .canAccess(CrudConstants.NetworkElementCrud, 'u')
      .subscribe((canAccess) => {
        this.isReadOnly = !canAccess;
      });
  }

  loadSignals(): void {
    this.setLoading(true);

    if (this.disableInitialLoad) {
      this.configurationsHash = {};
      this._originalConfigurationsHash = {};
      this.configurationHasChanged = false;
      this.excludedSignals = [];
    } else {
      this._customerClassesReadySubs?.unsubscribe();
      this._customerClassesReadySubs = this._customerClassesReady$.asObservable().subscribe(() => {
        this._smartMetersService
          .getConfigurations(this.selectedNE.hierarchyElementId)
          .pipe(finalize(() => this.setLoading(false)))
          .subscribe({
            next: (configurations) => {
              if (configurations) {
                this.configurationsHash = this.customerClassTypes.reduce(
                  (accum, customerClassType) => {
                    const existingConfig = configurations.find(
                      (config) =>
                        config.customerClassTypeId === customerClassType.customerClassTypeId
                    );
                    if (existingConfig) {
                      accum[customerClassType.customerClassTypeId] = existingConfig;
                    }
                    return accum;
                  },
                  {}
                );
                this.notifyChanges();

                this._originalConfigurationsHash = globalUtilsHelper.clone(
                  this.configurationsHash,
                  true
                );

                this.configurationHasChanged = false;
                this.excludedSignals = [];
              }
            },
          });
      });
    }
  }

  prepareListsSettings(): void {
    this.settings = new DragListSettings({
      dataService: this.historicalMode ? this._historicalSignalServiceName : this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._pointDescriptionFieldName, dir: 'asc' }],
      useQueryParams: true,
      displayFieldName: this._titleFieldName,
      idFieldName: this._pointIdFieldName,
      isReadOnly: this.isReadOnly,
      scrollId: this.widgetId,
    });

    this.settingsCustom = new DragListCustomSettings({
      hideFilter: true,
      emptyLegendKey: this.T_SCOPE + '.messages.drag-list-empty-message',
      isReadOnly: this.isReadOnly,
      useSingleContainer: true,
      allowDropCallback: this.allowDrop,
    });

    this.cardSettings = new DragListCardSettings({
      fields: [this._pointIdFieldName, this._pointDescriptionFieldName],
      fieldLabels: {
        pointDescription: this._pointDescriptionFieldName,
        pointId: this._pointIdFieldName,
      },
      iconName: 'card-handler',
      isSvg: true,
      isReadOnly: this.isReadOnly,
    });
  }

  private allowDrop = (dropData: AllowDropCallbackData): boolean => {
    const customerClassTypeId = dropData.dropList.data[0]?.customerClassTypeId;
    if (customerClassTypeId) {
      return !this.configurationsHash[customerClassTypeId];
    }
    return true;
  };

  updateQueryParams(): void {
    const newParams = new Map<string, any>();
    newParams.set(this._elementIdParamName, this.selectedNE?.elementId);
    newParams.set(this._isZoneParamName, this.selectedNE?.isZone);

    this.queryParams = newParams;
  }

  compareSignals(): void {
    this.configurationHasChanged = !globalUtilsHelper.deepEqual(
      this._originalConfigurationsHash,
      this.configurationsHash
    );
  }

  listHasChanged(current: AvailableSignalDto[], original: AvailableSignalDto[]): boolean {
    const configurationHasChanged = !this._arrayHelperService.areSame(current, original);
    return configurationHasChanged;
  }

  onDroppedElementInSource(droppedElement: any): void {
    const isConfigElement = !!droppedElement?.customerClassTypeId;
    if (isConfigElement) {
      this.excludedSignals = this.excludedSignals.filter(
        (signal) => signal.signalId !== droppedElement.signalId
      );
      delete this.configurationsHash[droppedElement.customerClassTypeId];
      delete droppedElement.customerClassTypeId;
      this.refreshConfigs();
      this.notifyChanges();
    }
  }

  onDroppedElement(selectedCustomerClassType: ICustomerClassTypeDto, droppedElement: any): void {
    const isSignalElement = !droppedElement?.customerClassTypeId;

    if (isSignalElement) {
      this.onDropElementSignal(selectedCustomerClassType, droppedElement);
    } else {
      this.onDropElementConfig(selectedCustomerClassType, droppedElement);
    }
  }

  private reset(): void {
    this.excludedSignals = [];
    this.configurationsHash = {};
    this._originalConfigurationsHash = {};
    this.refreshConfigs();
    this.notifyChanges();
  }

  /**
   * Dropped a signal from the signals selector.
   */
  private onDropElementSignal(
    selectedCustomerClassType: ICustomerClassTypeDto,
    availableSignal: AvailableSignalDto
  ): void {
    let newExcludedSignals = [...this.excludedSignals];
    for (let key in this.configurationsHash) {
      const config = this.configurationsHash[key];
      if (config.customerClassTypeId === selectedCustomerClassType.customerClassTypeId) {
        newExcludedSignals = newExcludedSignals.filter(
          (signal) => signal.signalId === config.signalId
        );
        delete this.configurationsHash[key];
      }
    }

    const { signalId, pointId, pointDescription } = availableSignal;
    this.configurationsHash[selectedCustomerClassType.customerClassTypeId] = {
      signalId,
      pointId,
      pointDescription,
      hierarchyElementId: this.selectedNE.hierarchyElementId,
      customerClassTypeId: selectedCustomerClassType.customerClassTypeId,
      customerClassTypeName: selectedCustomerClassType.customerClassTypeName,
    } as SmartMeterConfigDto;

    newExcludedSignals.push(availableSignal);
    this.excludedSignals = [...newExcludedSignals];
    this.notifyChanges();
  }

  /**
   * When the dropped element is already a config, we are just moving them from customer classes.
   * In this case the excluded signals do not change.
   */
  private onDropElementConfig(
    targetCustomerClassType: ICustomerClassTypeDto,
    movingConfig: SmartMeterConfigDto
  ): void {
    const previousClassTypeId = movingConfig.customerClassTypeId;
    const previousClassTypeName = movingConfig.customerClassTypeName;
    const newClassTypeId = targetCustomerClassType.customerClassTypeId;
    const newClassTypeName = targetCustomerClassType.customerClassTypeName;

    const previousConfig = this.configurationsHash[newClassTypeId];

    this.updateCustomerClassType(movingConfig, newClassTypeId, newClassTypeName);
    this.updateCustomerClassType(previousConfig, previousClassTypeId, previousClassTypeName);
    this.refreshConfigs();
    this.notifyChanges();
  }

  private updateCustomerClassType(
    config: SmartMeterConfigDto,
    newCustomerClassTypeId: string,
    newCustomerClassTypeName: string
  ): void {
    if (config) {
      config.customerClassTypeId = newCustomerClassTypeId;
      config.customerClassTypeName = newCustomerClassTypeName;
      this.configurationsHash[newCustomerClassTypeId] = config;
    } else {
      delete this.configurationsHash[newCustomerClassTypeId];
    }
    this.notifyChanges();
  }

  private refreshConfigs(): void {
    this.customerClassTypes = globalUtilsHelper.clone(this.customerClassTypes, true);
  }

  onSave(): void {
    this.save().subscribe(() => { });
  }

  private notifyChanges(): void {
    if (this.selectedNE) {
      const saveDto = this.buildSmartMeterSaveDto();
      this.formChanges.emit(saveDto);
    }
  }

  private buildSmartMeterSaveDto(): SmartMeterSaveDto {
    const saveDto: SmartMeterSaveDto = {
      hierarchyElementId: this.selectedNE.hierarchyElementId,
      hierarchyElementName: this.selectedNE.hierarchyElementName,
      configurations: Object.values(this.configurationsHash),
    };

    return saveDto;
  }

  save(): Observable<boolean> {
    this.setLoading(true);

    return this.checkBeforeSave().pipe(
      switchMap((beforeSaveResult) => {
        const saveDto = this.buildSmartMeterSaveDto();
        saveDto.updateCalculationModes = beforeSaveResult.answer;
        saveDto.changes = this.includeCustomerClassTypeNames(beforeSaveResult.changes);

        return this._smartMetersService
          .save(saveDto)
          .pipe(finalize(() => this.setLoading(false)))
          .pipe(
            tap((result: InformedResponse) => {
              const errorShown = this.showErrorSavingDialog(result);
              if (!errorShown) {
                this._dialogService.showTranslatedMessageInSnackBar({
                  translateKey: `${this.T_SCOPE}.messages.save-success`,
                  icon: 'success',
                });
                this.updateOriginalLists();
                this.reloadList$.next();
              }
            }),
            catchError((error) => {
              this.showErrorSavingDialog(null);
              this.setLoading(false);
              return of(null);
            }),
            map((_) => true)
          );
      })
    );
  }

  private showErrorSavingDialog(result?: InformedResponse): boolean {
    let messageKey = null;
    if (!result) {
      messageKey = 'messages.save-error';
    } else if (!result.success) {
      messageKey = result.message;
    }

    if (messageKey) {
      const errorMessageKey = `${this.T_SCOPE}.${messageKey}`;
      this.showErrorDialog(errorMessageKey);
    }

    return !!messageKey;
  }

  private showErrorDialog(errorMessageKey: string): void {
    let dialogSettings = new WlmDialogSettings({ translateKey: errorMessageKey });
    dialogSettings.icon = 'error';
    this._dialogService.showTranslatedMessage(dialogSettings);
  }

  private checkBeforeSave(): Observable<CheckBeforeSaveResult> {
    this.setLoading(true);
    const saveDto = this.buildSmartMeterSaveDto();

    return this._smartMetersService.checkBeforeSave(saveDto).pipe(
      filter((response: SmartMeterChangesInformedDto) => {
        const errorShown = this.showErrorSavingDialog(response);

        if (errorShown) {
          this.setLoading(false);
        }

        return !errorShown;
      }),
      switchMap((response: SmartMeterChangesInformedDto) =>
        forkJoin({
          processedChanges: this._smartMetersChangesService.processChanges(
            response.changes,
            this.selectedNE.hierarchyElementTypeId
          ),
          changes: of(response.changes),
        })
      ),

      switchMap(({ processedChanges, changes }) => {
        if (processedChanges.length === 0) {
          // if there is no changes, the popup shouldn't display
          return of({
            answer: false,
            changes: [],
          });
        }

        const processedChangesSorted = asEnumerable(processedChanges)
          .OrderByDescending((cct) => cct.isMeasured)
          .ThenBy((cct) => cct.customerClassTypeLabel)
          .ToArray();

        return this.buildAskAutomaticPopup(processedChangesSorted).pipe(
          map((answer) => ({
            answer,
            changes,
          }))
        );
      }),
      catchError((error) => {
        this.showErrorSavingDialog(null);
        this.setLoading(false);
        return of(null);
      })
    );
  }

  private buildAskAutomaticPopup(
    processedChanges: SmartMeterProcessedChanges[]
  ): Observable<boolean> {
    const renderedComponent = this._componentRendererService.injectComponentWithoutDestroyIt(
      SmartMetersChangesComponent,
      'span',
      (component) => {
        component.processedChanges = processedChanges;
      }
    );

    return this._dialogService
      .showTranslatedDialogMessage(
        new WlmDialogSettings({
          translateKey: `${this.T_SCOPE}.messages.ask-automatic-update`,
          html: renderedComponent,
          icon: 'info',
          confirmButtonTextKey: `${this.T_SCOPE}.messages.ask-automatic-update-ok`,
          denyButtonTextKey: `${this.T_SCOPE}.messages.ask-automatic-update-cancel`,
        })
      )
      .pipe(map((popupResult) => popupResult.result));
  }

  updateOriginalLists(): void {
    this._originalConfigurationsHash = globalUtilsHelper.clone(this.configurationsHash);
    this.configurationHasChanged = false;
  }

  onExcludedListChange(signals): void {
    this.excludedSignals = signals;
  }

  discard(): void {
    this.configurationsHash = globalUtilsHelper.clone(this._originalConfigurationsHash);

    this.excludedSignals = [];
    this.reloadList$.next();
    this.configurationHasChanged = false;
    this.refreshConfigs();
    this.notifyChanges();
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private includeCustomerClassTypeNames(changes: SmartMeterChangesDto[]): SmartMeterChangesDto[] {
    return changes.map((change) => {
      const cct = this.customerClassTypes.find(
        (cct) => cct.customerClassTypeId === change.customerClassTypeId
      );
      change.name = cct.customerClassTypeName;
      return change;
    });
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: COMPONENT_SELECTOR,
      hasValidChanges: hasChanges,
      saveFn: () => this.save(),
    };
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, COMPONENT_SELECTOR);
  }
}
