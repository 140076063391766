import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import * as DOMPurify from 'dompurify';
import { CommonSharedModule } from '../../shared/common-shared.module';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';
import { isSvgValidator } from '../is-svg.validator';

@Component({
  selector: 'wlm-svg-editor',
  templateUrl: './svg-editor.component.html',
  styleUrls: ['./svg-editor.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
    CommonSharedModule,
  ],
})
export class SvgEditorComponent implements OnInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() previewLabel: string;
  @Input() previewPlaceholder: string;

  private _value: string;
  get value(): string {
    return this._value;
  }
  @Input() set value(value: string) {
    this.sanitizedValue = this.sanitizeAndPrettify(value);
    this.previewValue = this.prettifySvg(value);
    this.updatePreview();

    this._value = this.sanitizedValue;
    this.unsecuredCtrl.setValue(this.value, { emitEvent: false });
  }
  @Output() valueChange = new EventEmitter<string>();
  @Output() validityChange = new EventEmitter<ValidationErrors>();
  @ViewChild('svgContent') svgContent: ElementRef<HTMLElement>;

  readonly unsecuredCtrl = new FormControl();
  private readonly _destroyRef = inject(DestroyRef);
  sanitizedValue: string;
  // PreviewValue is allowed to have incorrect format as it is just for editing
  previewValue: string;
  noPreviewMessageKey = 'common.messages.no-preview';
  noPreviewMessage: string;

  constructor(private _localization: LocalizationHelperService) {
    this.unsecuredCtrl.setValidators([isSvgValidator()]);
    this._localization.get(this.noPreviewMessageKey).subscribe((tsNoPreview) => {
      this.noPreviewMessage = tsNoPreview;
    });
  }

  ngOnInit(): void {
    this.unsecuredCtrl.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((value) => {
        this.sanitizedValue = this.sanitizeAndPrettify(value);
        this.previewValue = this.prettifySvg(value);
        this.updatePreview();

        if (this.sanitizedValue) {
          this.valueChange.next(this.sanitizedValue);
        }

        this.validityChange.next(this.unsecuredCtrl.errors);
      });
  }

  private updatePreview(): void {
    let preview = this.previewValue;

    if (!/^<svg\s/.test(this.previewValue)) {
      preview = this.noPreviewMessage;
    }

    if (this.svgContent) {
      this.svgContent.nativeElement.innerHTML = preview;
    }
  }

  private sanitizeSvg(value: string): string {
    return DOMPurify.sanitize(value, { USE_PROFILES: { svg: true } });
  }

  private sanitizeAndPrettify(value: string): string {
    let sanitizedValue = this.sanitizeSvg(value);
    sanitizedValue = sanitizedValue?.replace(/(\r\n|\n|\r|\t)/gm, '');
    sanitizedValue = this.prettifySvg(sanitizedValue);
    // Sanitize again in case of prettifySvg is an external library.
    const result = this.sanitizeSvg(sanitizedValue);
    return result;
  }

  private prettifySvg(value: string): string {
    return globalUtilsHelper.prettifySvg(value)?.replace('>>', '>')
  }
}
