import { Injectable, inject } from '@angular/core';
import { Observable, map, of, switchMap, take } from 'rxjs';
import { FieldDataType } from 'src/app/common-modules/dynamic-forms/models/field-data-type';
import { FieldDefinition } from 'src/app/common-modules/dynamic-forms/models/field-definition';
import { FieldInputSubTypes } from 'src/app/common-modules/dynamic-forms/models/field-input-subtypes';
import { FieldsByCategory } from 'src/app/common-modules/dynamic-forms/models/fields-by-category';
import { FormGlobalValues } from 'src/app/common-modules/dynamic-forms/models/form-values-data-source';
import { settingsDataSourceStrMapping } from 'src/app/common-modules/dynamic-forms/models/settings-data-source.enum';
import { BaseFormLabelOverwriteService } from 'src/app/common-modules/dynamic-forms/services/base-form-label-overwrite.service';
import { DynamicFormService } from 'src/app/common-modules/dynamic-forms/services/dynamic-form.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { SharedConstantsService } from 'src/app/common-modules/shared/constants/shared-constants.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { UoMService } from 'src/app/common-modules/shared/uom/uom.service';
import { LogService } from 'src/app/common-modules/shared/wlm-log/log.service';

@Injectable({ providedIn: 'root' })
export class GlobalValueLabelOverwriteService extends BaseFormLabelOverwriteService {
  private readonly _dynamicFormService: DynamicFormService = inject(DynamicFormService);
  private readonly _uomService: UoMService = inject(UoMService);
  private readonly _localization: LocalizationHelperService = inject(LocalizationHelperService);
  private readonly _log: LogService = inject(LogService);
  private readonly _sharedConstants = inject(SharedConstantsService);
  private readonly _tsPath = `${AppModules.Configuration}.global-value-label-overwrite-service`;
  private readonly _labelKeys = {
    messageWithLabel: `${this._tsPath}.message-with-label`,
    message: `${this._tsPath}.message`,
  };

  apply(field: FieldDefinition, currentLabel: string, translationKey?: string): Observable<string> {
    translationKey = translationKey ?? field.globalDataSource.labelKey;

    return this._dynamicFormService.globalValues$.pipe(
      switchMap((globalValues: FormGlobalValues) =>
        this._localization.get(currentLabel).pipe(
          take(1),
          map((translatedLabel) => ({
            globalValues,
            translatedLabel,
          }))
        )
      ),
      switchMap((data: { globalValues: FormGlobalValues; translatedLabel: string }) => {
        const { globalValues, translatedLabel } = data;
        if (!field.globalDataSource || !globalValues || Object.keys(globalValues).length === 0) {
          return of(translatedLabel);
        }

        const globalValue = this.getGlobalValue(globalValues, field);
        if (globalValue === null || typeof globalValue === 'undefined') {
          return of(translatedLabel);
        }

        return this.formatValue(globalValue, field).pipe(
          switchMap((convertedValue) => {
            const finalTranslationKey = this.getFinalTranslationKey(
              translationKey,
              translatedLabel
            );
            return this._localization.get(finalTranslationKey, {
              currentLabel: translatedLabel,
              globalValue: convertedValue,
            });
          })
        );
      })
    );
  }

  getGlobalValue$(field: FieldDefinition): Observable<any> {
    return this._dynamicFormService.globalValues$.pipe(
      map((globalValues: FormGlobalValues) => this.getGlobalValue(globalValues, field))
    );
  }

  getFieldsByCategory$(): Observable<FieldsByCategory[]> {
    return this._dynamicFormService.fieldsByCategory$;
  }

  private getFinalTranslationKey(translationKey: string, translatedLabel: string): string {
    if (translationKey) {
      return translationKey;
    }
    return translatedLabel && translatedLabel !== ''
      ? this._labelKeys.messageWithLabel
      : this._labelKeys.message;
  }

  private getGlobalValue(globalValues: FormGlobalValues, field: FieldDefinition): string | null {
    const { identifier, dataSource, parentTypeId } = field.globalDataSource;
    if (globalValues[parentTypeId]) {
      const valuesByDataSrc =
        globalValues[parentTypeId][dataSource] ??
        globalValues[parentTypeId][settingsDataSourceStrMapping.get(dataSource)];

      if (typeof valuesByDataSrc === 'undefined') {
        this._log.error({
          msg: `The dataSource ${dataSource} may not be registered in settingsDataSourceStrMapping.`,
        });
        return null;
      }

      const value = valuesByDataSrc.values.find((item) => {
        const idA = globalUtilsHelper.normalizeId(item.key);
        const idB = globalUtilsHelper.normalizeId(identifier);
        return idA === idB;
      });
      return value?.value;
    }
    return null;
  }

  private formatValue(value: string, field: FieldDefinition): Observable<string> {
    const { globalDataSource } = field;
    const hierarchyElementTypeId = globalDataSource.parentTypeId ?? undefined;

    if (globalDataSource.dataType === FieldDataType.Bool) {
      return this.formatBooleanValue(value, globalDataSource);
    }

    if (this.fieldIsPercentage(field)) {
      return of(this.formatPercentageValue(value));
    }

    if (globalDataSource.valueMapper) {
      return this.applyMapper(value, globalDataSource);
    }

    return this._uomService
      .getByFromUnit(
        field.dimensionTypeId,
        field.unitTypeId,
        field.timeAggregationId,
        hierarchyElementTypeId
      )
      .pipe(
        map((unit) => {
          if (unit) {
            let result = this._uomService.uomMultiply(
              value,
              String(unit.conversionFactor),
              false,
              field.dimensionTypeId
            );
            result = `${result} ${unit.unitTypeToDescription}`;
            return result;
          } else {
            return value;
          }
        })
      );
  }

  private formatPercentageValue(value) {
    return `${(+value * 100).toString()}%`;
  }

  private fieldIsPercentage(source: FieldDefinition): boolean {
    return source?.input?.subtype === FieldInputSubTypes.Percent;
  }

  private formatBooleanValue(value, globalDataSource): Observable<string> {
    let mapperFn;

    if (globalDataSource.valueMapper && this._sharedConstants[globalDataSource.valueMapper]) {
      mapperFn = this._sharedConstants[globalDataSource.valueMapper];
    } else {
      mapperFn = this._sharedConstants.getBooleanColumnDefaultMapping;
    }

    return mapperFn().pipe(
      take(1),
      map((mapping: Map<boolean, string>) => {
        const booleanValue = this.toBoolean(value);
        const result = mapping.get(booleanValue);
        return result;
      })
    );
  }

  private applyMapper(value, globalDataSource): Observable<string> {
    if (globalDataSource.valueMapper && this._sharedConstants[globalDataSource.valueMapper]) {
      const mapperFn = this._sharedConstants[globalDataSource.valueMapper];

      return mapperFn().pipe(
        take(1),
        map((mapping: Map<string, string>) => {
          const result = mapping.get(value);
          return result;
        })
      );
    }

    return of(value);
  }

  private toBoolean(value: string): boolean {
    const lowerValue = value?.toLowerCase();
    if (lowerValue === 'true') {
      return true;
    }
    if (lowerValue === 'false') {
      return false;
    }
    return null;
  }
}
