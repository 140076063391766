import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { DragListCardSettings } from 'src/app/common-modules/shared/core/drag-list-card/drag-list-card-settings';
import { DragListCustomSettings } from 'src/app/common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { DimensionTypesEnum } from 'src/app/common-modules/shared/model/shared/dimension-types';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { LarsSworpsConfigurationDto } from '../../shared/model/signals/lars-sworps-configuration.dto';
import { LarsAndSworpsSiteSignalsDto } from '../../shared/model/signals/lars-sworps-site-signal.dto';
import { SaveLarsAndSworpsSiteSignalResponseDto } from '../../shared/model/signals/save-lars-and-sworps-site-signal-response.dto';
import { LarsSworpsHistoricalValidationService } from '../ne-configuration-lars-sworps-historical/lars-sworps-historical-validations.service';
import { LarsSworpsCurrentMessagesService } from './lars-sworps-current-message.service';
import { LarsSworpsLists } from './lars-sworps-lists.enum';
import { NeConfigurationLarsSworpsService } from './ne-configuration-lars-sworps.service';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-lars-sworps';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-lars-sworps.component.html',
  styleUrls: ['./ne-configuration-lars-sworps.component.scss'],
})
export class NeConfigurationLarsSworpsComponent
  extends BaseWidgetComponent
  implements OnInit, IPendingChangesEmitter {
  private _selectedNE: INetworkElementDto;

  public get selectedNE(): INetworkElementDto {
    return this._selectedNE;
  }

  @Input() public set selectedNE(value: INetworkElementDto) {
    if (value) {
      this._selectedNE = value;
      this.updateQueryParams();
      this.loadSignals();
    }
  }

  @Input() pageId: string;
  @Input() widgetId: string;

  @Input() isReadOnly = true;

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  get componentName(): string {
    return 'NeConfigurationLarsSworpsComponent';
  }
  private _configurationHasChanged = false;
  public get configurationHasChanged() {
    return this._configurationHasChanged;
  }
  public set configurationHasChanged(value) {
    this._configurationHasChanged = value;
    this.setPendingChanges(this.pageId, this.getPendingChanges(value));

    if (this._mustClearErrors) {
      this.clearCardErrors();
    }
  }

  reloadList$ = new Subject<void>();
  settings: DragListSettings;
  settingsCustom: DragListCustomSettings;
  larsAdditionSettings: DragListCustomSettings;
  larsSubtractionSettings: DragListCustomSettings;
  sworpsAdditionSettings: DragListCustomSettings;
  sworpsSubtractionSettings: DragListCustomSettings;
  cardSettings: DragListCardSettings;
  queryParams: Map<string, any>;
  larsSubstraction: LarsAndSworpsSiteSignalsDto[] = [];
  larsAddition: LarsAndSworpsSiteSignalsDto[] = [];
  sworpsSubstraction: LarsAndSworpsSiteSignalsDto[] = [];
  sworpsAddition: LarsAndSworpsSiteSignalsDto[] = [];
  excludedSignals: LarsAndSworpsSiteSignalsDto[] = [];
  refreshList$ = new Subject<void>();
  toggleSelectorValue = 'lars';
  larsSworpsLists = LarsSworpsLists;

  private _pointsODataFilters: Map<string, any>;
  private _originalLarsSubstraction: LarsAndSworpsSiteSignalsDto[] = [];
  private _originalLarsAddition: LarsAndSworpsSiteSignalsDto[] = [];
  private _originalSworpsSubstraction: LarsAndSworpsSiteSignalsDto[] = [];
  private _originalSworpsAddition: LarsAndSworpsSiteSignalsDto[] = [];
  private _titleFieldName = 'title';
  private _pointIdFieldName = 'pointId';
  private _pointDescriptionFieldName = 'pointDescription';
  private _elementIdParamName = 'networkElementId';
  private _dimensionTypeIdFieldName = 'dimensionTypeId';
  private _isPointOfInterestFieldName = 'isPointOfInterest';
  private _flowDimensionTypeId = DimensionTypesEnum.Flow;
  private _serviceName = 'NeConfigurationLarsSworpsService';
  private _dragListServiceName = 'AvailableSignalsService';
  private _errorField = 'hasError';
  private _pagesize = 50;

  private _larsKey = `${this.T_SCOPE}.lars-selector`;
  private _sworpsKey = `${this.T_SCOPE}.sworps-selector`;
  private _substractionKey = `${this.T_SCOPE}.substraction-text`;
  private _additionKey = `${this.T_SCOPE}.addition-text`;
  private _larsText: string;
  private _sworpsText: string;
  private _substractionText: string;
  private _additionText: string;
  private _mustClearErrors = false;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _larsWorpsService: NeConfigurationLarsSworpsService,
    private readonly _dialogService: DialogService,
    private readonly _arrayHelperService: ArrayHelperService,
    private readonly _spinnerService: SpinnerService,
    private readonly _larsSworpsValidationsService: LarsSworpsHistoricalValidationService,
    private readonly _localizationService: LocalizationHelperService,
    private readonly _objectHelperService: ObjectHelperService,
    private readonly _larsSworpsMessagesService: LarsSworpsCurrentMessagesService,
    private readonly _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.prepareListsSettings();
    this.getBaseTranslations();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) { }
  init(): void { }

  loadSignals() {
    this._spinnerService.setLoading(true, this.componentName);
    this._larsWorpsService.getLarsSworpsSignalsByNE(this.selectedNE.networkElementId).subscribe({
      next: (larsSworpsConfiguration) => {
        if (larsSworpsConfiguration) {
          const sortedLarsSworpsConfiguration =
            this._arrayHelperService.sortArrayObjectCaseInsensitive(
              larsSworpsConfiguration,
              this._pointDescriptionFieldName
            );

          this.larsSubstraction = this.filterLarsSworpConfigurations(
            sortedLarsSworpsConfiguration,
            true,
            true
          );

          this._originalLarsSubstraction = this._objectHelperService.clone(this.larsSubstraction);

          this.larsAddition = this.filterLarsSworpConfigurations(
            sortedLarsSworpsConfiguration,
            true,
            false
          );

          this._originalLarsAddition = this._objectHelperService.clone(this.larsAddition);

          this.sworpsSubstraction = this.filterLarsSworpConfigurations(
            sortedLarsSworpsConfiguration,
            false,
            true
          );

          this._originalSworpsSubstraction = this._objectHelperService.clone(
            this.sworpsSubstraction
          );

          this.sworpsAddition = this.filterLarsSworpConfigurations(
            sortedLarsSworpsConfiguration,
            false,
            false
          );

          this._originalSworpsAddition = this._objectHelperService.clone(this.sworpsAddition);

          this.configurationHasChanged = false;
          this.excludedSignals = [];
        }
        this._spinnerService.setLoading(false, this.componentName);
      },
    });
  }

  prepareListsSettings() {
    this.prepareODataFilters();

    this.settings = new DragListSettings({
      dataService: this._dragListServiceName,
      pageSize: this._pagesize,
      useQueryParams: true,
      displayFieldName: this._titleFieldName,
      isReadOnly: this.isReadOnly,
      cacheOptions: { avoid: true },
      oDataFilters: this._pointsODataFilters,
      scrollId: this.widgetId,
      disableDropItems: true,
    });

    const baseCustomSettings = new DragListCustomSettings({
      hideFilter: true,
      emptyLegendKey: this.T_SCOPE + '.messages.drag-list-empty-message',
      isReadOnly: this.isReadOnly,
    });

    this.larsAdditionSettings = {
      ...baseCustomSettings,
      beforeDropCallback: this.allowDropLarsAddition,
      useCallback: true,
    };

    this.larsSubtractionSettings = {
      ...baseCustomSettings,
      beforeDropCallback: this.allowDropLarsSubtraction,
      useCallback: true,
    };

    this.sworpsAdditionSettings = {
      ...baseCustomSettings,
      beforeDropCallback: this.allowDropSworpsAddition,
      useCallback: true,
    };

    this.sworpsSubtractionSettings = {
      ...baseCustomSettings,
      beforeDropCallback: this.allowDropSworpsSubtraction,
      useCallback: true,
    };

    this.settingsCustom = { ...baseCustomSettings };

    this.cardSettings = new DragListCardSettings({
      fields: [this._pointIdFieldName, this._pointDescriptionFieldName],
      fieldLabels: {
        pointDescription: this._pointDescriptionFieldName,
        pointId: this._pointIdFieldName,
      },
      iconName: 'card-handler',
      isSvg: true,
      isReadOnly: this.isReadOnly,
      errorField: this._errorField,
    });
  }

  allowDropLarsAddition = (larsSworpSignal: LarsAndSworpsSiteSignalsDto): Observable<any> => {
    const validationResult = this.checkDropOperation(larsSworpSignal, true, false);
    return validationResult ? of(larsSworpSignal) : of(null);
  };

  allowDropLarsSubtraction = (larsSworpSignal: LarsAndSworpsSiteSignalsDto): Observable<any> => {
    const validationResult = this.checkDropOperation(larsSworpSignal, true, true);
    return validationResult ? of(larsSworpSignal) : of(null);
  };

  allowDropSworpsAddition = (larsSworpSignal: LarsAndSworpsSiteSignalsDto): Observable<any> => {
    const validationResult = this.checkDropOperation(larsSworpSignal, false, false);
    return validationResult ? of(larsSworpSignal) : of(null);
  };

  allowDropSworpsSubtraction = (larsSworpSignal: LarsAndSworpsSiteSignalsDto): Observable<any> => {
    const validationResult = this.checkDropOperation(larsSworpSignal, false, true);
    return validationResult ? of(larsSworpSignal) : of(null);
  };

  private checkDropOperation(
    larsSworpSignal: LarsAndSworpsSiteSignalsDto,
    isLars: boolean,
    isSubtration: boolean
  ): boolean {
    const auxIsLars = larsSworpSignal.isLars;
    const auxIsSubtraction = larsSworpSignal.isSubtraction;

    larsSworpSignal.isLars = isLars;
    larsSworpSignal.isSubtraction = isSubtration;

    const validationResult = this.validateCurrentConfiguration(larsSworpSignal);
    larsSworpSignal.isLars = auxIsLars;
    larsSworpSignal.isSubtraction = auxIsSubtraction;

    return validationResult;
  }

  updateQueryParams() {
    const newParams = new Map<string, any>();
    newParams.set(this._elementIdParamName, this.selectedNE?.networkElementId);

    this.queryParams = newParams;
  }

  compareSignals() {
    if (this.listHasChanged(this.larsAddition, this._originalLarsAddition)) {
      this.configurationHasChanged = true;
      return;
    }

    if (this.listHasChanged(this.larsSubstraction, this._originalLarsSubstraction)) {
      this.configurationHasChanged = true;
      return;
    }

    if (this.listHasChanged(this.sworpsAddition, this._originalSworpsAddition)) {
      this.configurationHasChanged = true;
      return;
    }

    if (this.listHasChanged(this.sworpsSubstraction, this._originalSworpsSubstraction)) {
      this.configurationHasChanged = true;
      return;
    }

    this.configurationHasChanged = false;
  }

  private clearCardErrors() {
    this.clearErrors(this.larsAddition);
    this.clearErrors(this.larsSubstraction);
    this.clearErrors(this.sworpsAddition);
    this.clearErrors(this.sworpsSubstraction);
    this._mustClearErrors = false;
  }

  private clearErrors(configs: LarsAndSworpsSiteSignalsDto[]): void {
    configs.forEach((config) => (config.hasError = false));
  }

  listHasChanged(current: LarsAndSworpsSiteSignalsDto[], original: LarsAndSworpsSiteSignalsDto[]) {
    const configurationHasChanged = !this._arrayHelperService.areSame(current, original);
    return configurationHasChanged;
  }

  onSignalsChanged($event) {
    this.refreshList$.next();
  }

  onDroppedElement(selectedPoint: LarsAndSworpsSiteSignalsDto, selectedList: LarsSworpsLists) {
    if (selectedPoint) {
      this.removeElementFromList(selectedPoint);
      switch (selectedList) {
        case LarsSworpsLists.LarsAddition:
          selectedPoint.isLars = true;
          selectedPoint.isSubtraction = false;
          this.larsAddition.push(selectedPoint);
          break;

        case LarsSworpsLists.LarsSubtraction:
          selectedPoint.isLars = true;
          selectedPoint.isSubtraction = true;
          this.larsSubstraction.push(selectedPoint);
          break;

        case LarsSworpsLists.SworpsAddition:
          selectedPoint.isLars = false;
          selectedPoint.isSubtraction = false;
          this.sworpsAddition.push(selectedPoint);
          break;

        case LarsSworpsLists.SworpsSubtraction:
          selectedPoint.isLars = false;
          selectedPoint.isSubtraction = true;
          this.sworpsSubstraction.push(selectedPoint);
          break;
      }
    }

    this.reloadList$.next();
  }

  private removeElementFromList(selectedPoint: LarsAndSworpsSiteSignalsDto) {
    if (selectedPoint.isLars === undefined || selectedPoint.isSubtraction === undefined) {
      return;
    }

    if (selectedPoint.isLars && selectedPoint.isSubtraction) {
      this.larsSubstraction = this.filterConfigs(this.larsSubstraction, selectedPoint);
    }

    if (selectedPoint.isLars && !selectedPoint.isSubtraction) {
      this.larsAddition = this.filterConfigs(this.larsAddition, selectedPoint);
    }

    if (!selectedPoint.isLars && selectedPoint.isSubtraction) {
      this.sworpsSubstraction = this.filterConfigs(this.sworpsSubstraction, selectedPoint);
    }

    if (!selectedPoint.isLars && !selectedPoint.isSubtraction) {
      this.sworpsAddition = this.filterConfigs(this.sworpsAddition, selectedPoint);
    }
  }

  private filterConfigs(
    configs: LarsAndSworpsSiteSignalsDto[],
    selectedConfig: LarsAndSworpsSiteSignalsDto
  ): LarsAndSworpsSiteSignalsDto[] {
    return configs.filter((f) => f.pointId != selectedConfig.pointId);
  }

  private getBaseTranslations() {
    const larsText$ = this._localizationService.get(this._larsKey);
    const sworpsText$ = this._localizationService.get(this._sworpsKey);
    const substractionText$ = this._localizationService.get(this._substractionKey);
    const additionText$ = this._localizationService.get(this._additionKey);

    forkJoin([larsText$, sworpsText$, substractionText$, additionText$]).subscribe(
      ([larsText, sworpsText, substractionText, additionText]) => {
        this._larsText = larsText;
        this._sworpsText = sworpsText;
        this._substractionText = substractionText;
        this._additionText = additionText;
      }
    );
  }

  private validateCurrentConfiguration(
    draggedConfiguration?: LarsAndSworpsSiteSignalsDto
  ): boolean {
    let currentConfig = [];
    currentConfig = currentConfig.concat(this.larsAddition);
    currentConfig = currentConfig.concat(this.larsSubstraction);
    currentConfig = currentConfig.concat(this.sworpsAddition);
    currentConfig = currentConfig.concat(this.sworpsSubstraction);
    currentConfig.push(draggedConfiguration);

    const conflicts =
      this._larsSworpsValidationsService.getConflictsCurrentConfiguration(currentConfig);

    this.showCurrentSiteConflictsMessage(conflicts, draggedConfiguration);

    return conflicts.length == 0;
  }

  private showCurrentSiteConflictsMessage(
    conflicts: LarsAndSworpsSiteSignalsDto[],
    draggedConfiguration: LarsAndSworpsSiteSignalsDto
  ) {
    if (conflicts.length) {
      if (draggedConfiguration) {
        const index = conflicts.findIndex(
          (f) =>
            f.pointId == draggedConfiguration.pointId &&
            f.isLars == draggedConfiguration.isLars &&
            f.isSubtraction == draggedConfiguration.isSubtraction
        );
        conflicts.splice(index, 1);
      }

      const location = conflicts.map((m) => this.formatPointLocationText(m)).join(', ');

      const dialogSettings = new WlmDialogSettings({
        translateKey: `${this.T_SCOPE}.messages.conflict-with-points`,
        icon: 'warning',
        params: { location },
      });
      this._dialogService.showTranslatedMessage(dialogSettings);
    }
  }

  private formatPointLocationText(point: LarsAndSworpsSiteSignalsDto): string {
    const larsSworps = point.isLars ? this._larsText : this._sworpsText;
    const addSub = point.isSubtraction ? this._substractionText : this._additionText;
    return `${larsSworps}-${addSub}`;
  }

  onSave() {
    this.save().subscribe(() => { });
  }

  save(networkElement?: INetworkElementDto): Observable<boolean> {
    const networkElementToSave = networkElement ?? this.selectedNE;

    const larsAndSworpsConfiguration = new LarsSworpsConfigurationDto({
      networkElementId: networkElementToSave.networkElementId,
      networkElementName: networkElementToSave.networkElementName,
      larsSworpSignals: [],
    });

    this.larsAddition.forEach((signal) => {
      var larsSworpSignal = this.setLargeUserAssignedDto(signal, true, false);
      larsAndSworpsConfiguration.larsSworpSignals.push(larsSworpSignal);
    });

    this.larsSubstraction.forEach((signal) => {
      var larsSworpSignal = this.setLargeUserAssignedDto(signal, true, true);
      larsAndSworpsConfiguration.larsSworpSignals.push(larsSworpSignal);
    });

    this.sworpsAddition.forEach((signal) => {
      var larsSworpSignal = this.setLargeUserAssignedDto(signal, false, false);
      larsAndSworpsConfiguration.larsSworpSignals.push(larsSworpSignal);
    });

    this.sworpsSubstraction.forEach((signal) => {
      var larsSworpSignal = this.setLargeUserAssignedDto(signal, false, true);
      larsAndSworpsConfiguration.larsSworpSignals.push(larsSworpSignal);
    });

    this._spinnerService.setLoading(true, this.componentName);

    return this._larsWorpsService.saveLarsAndSworpsConfiguration(larsAndSworpsConfiguration).pipe(
      tap((saveResponse) => {
        if (saveResponse.success) {
          this._dialogService.showEntityActionSnackBar('save', 'configuration');
          this.updateQueryParams();
          this.loadSignals();
          this.reloadList$.next();
        }

        if (!saveResponse.success && saveResponse.relatedErrors.length) {
          this.prepareCrossSiteErrorMessage(saveResponse);
        }
        if (!saveResponse) {
          this.showErrorSavingDialog();
        }

        this._spinnerService.setLoading(false, this.componentName);
      }),
      catchError((error) => {
        this.showErrorSavingDialog();
        this._spinnerService.setLoading(false, this.componentName);
        return of(null);
      }),
      map((_) => true)
    );
  }

  private showErrorSavingDialog() {
    const errorMessageKey = `${this.T_SCOPE}.messages.save-error`;
    let dialogSettings = new WlmDialogSettings({ translateKey: errorMessageKey });
    dialogSettings.icon = 'error';

    this._dialogService.showTranslatedMessage(dialogSettings);
  }

  private prepareCrossSiteErrorMessage(saveResponse: SaveLarsAndSworpsSiteSignalResponseDto) {
    this.markCardsAsError(saveResponse.currentErrors);

    this._larsSworpsMessagesService.showValidationErrorsPopup(saveResponse.relatedErrors);
  }

  private markCardsAsError(currentErrors: LarsAndSworpsSiteSignalsDto[]) {
    currentErrors.forEach((errorConfig) => {
      if (errorConfig.isLars && errorConfig.isSubtraction) {
        this.setError(this.larsSubstraction, errorConfig);
      }

      if (!errorConfig.isLars && errorConfig.isSubtraction) {
        this.setError(this.sworpsSubstraction, errorConfig);
      }

      if (errorConfig.isLars && !errorConfig.isSubtraction) {
        this.setError(this.larsAddition, errorConfig);
      }

      if (!errorConfig.isLars && !errorConfig.isSubtraction) {
        this.setError(this.sworpsAddition, errorConfig);
      }
    });

    this._mustClearErrors = true;
  }

  setError(configs: LarsAndSworpsSiteSignalsDto[], errorConfig: LarsAndSworpsSiteSignalsDto): void {
    const foundConfig = configs.find((f) => f.pointId == errorConfig.pointId);
    if (foundConfig) {
      foundConfig.hasError = true;
    }
  }

  discard() {
    this.larsAddition = this._objectHelperService.clone(this._originalLarsAddition);
    this.larsSubstraction = this._objectHelperService.clone(this._originalLarsSubstraction);
    this.sworpsAddition = this._objectHelperService.clone(this._originalSworpsAddition);
    this.sworpsSubstraction = this._objectHelperService.clone(this._originalSworpsSubstraction);
    this.excludedSignals = [];
    this.reloadList$.next();

    this.configurationHasChanged = false;
  }

  private setLargeUserAssignedDto(
    larsSworpSignal: LarsAndSworpsSiteSignalsDto,
    isLars: boolean,
    isSubstraction: boolean
  ): LarsAndSworpsSiteSignalsDto {
    larsSworpSignal.isLars = isLars;
    larsSworpSignal.isSubtraction = isSubstraction;

    return larsSworpSignal;
  }

  private filterLarsSworpConfigurations(
    larsSworpConfiguration: LarsAndSworpsSiteSignalsDto[],
    isLars: boolean,
    isSubstraction: boolean
  ): LarsAndSworpsSiteSignalsDto[] {
    return larsSworpConfiguration.filter(
      (x) => x.isLars === isLars && x.isSubtraction === isSubstraction
    );
  }

  private prepareODataFilters() {
    this._pointsODataFilters = new Map<string, any>();

    this._pointsODataFilters.set(
      this._dimensionTypeIdFieldName,
      this._flowDimensionTypeId.valueOf()
    );

    this._pointsODataFilters.set(this._isPointOfInterestFieldName, true);
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: hasChanges,
      saveFn: () => this.save(),
    };
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
