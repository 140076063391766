import { Injectable, Injector } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ILeakageTypeGroupDto } from 'src/app/common-modules/dependencies/alc/leakage-type-group.dto';
import { GenericCrudService } from 'src/app/common-modules/generic-crud/generic-crud.service';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { IObjectValidationDto } from './leakage-type-group-validation.dto';

@Injectable()
export class LeakTypeGroupsCrudService
  extends BaseService
  implements GenericCrudService<ILeakageTypeGroupDto, number>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/leak-type-groups`;
  }

  getAll(): Observable<ILeakageTypeGroupDto[]> {
    return this.httpCacheClient.get<ILeakageTypeGroupDto[]>(this._entityUrl);
  }

  create(entity: ILeakageTypeGroupDto): Observable<ILeakageTypeGroupDto> {
    return this.httpCacheClient.post<ILeakageTypeGroupDto>(this._entityUrl, entity);
  }

  update(entity: ILeakageTypeGroupDto): Observable<ILeakageTypeGroupDto> {
    return this.httpCacheClient.put<ILeakageTypeGroupDto>(this._entityUrl, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  getId(entity: ILeakageTypeGroupDto): number {
    return entity.leakageTypeGroupId;
  }

  validate(
    entity: ILeakageTypeGroupDto,
    isCreate?: boolean
  ): Observable<IObjectValidationDto> {
    return this.httpCacheClient.post<IObjectValidationDto>(
      `${this._entityUrl}/validate?isCreate=${isCreate ? 'true' : 'false'}`,
      entity
    );
  }
}
