import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SignalInfoDto } from 'src/app/common-modules/dependencies/shared/model/signal-info.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { UtilsHelperService } from 'src/app/common-modules/shared/helpers/utils-helper.service';
import { HistoricalFormSettings } from 'src/app/water-loss/features/shared/historical/historical-form-settings';
import { HierarchyElementPressureSignalVersionDto } from 'src/app/water-loss/features/shared/model/signals/hierarchy-element-pressure-signal-version.dto';
import { PressureSelectionPopupSettings } from 'src/app/water-loss/smart-meters/models/pressure-selection-popup-settings';
import { HierarchyElementPressureSignalDto } from '../../../shared/model/signals/hierarchy-element-pressure-signal.dto';
import { PressureSignalConfigurationDto } from '../../../shared/model/signals/pressure-signal-configuration.dto';
import { PressureSignalSelectionPopupResponse } from './pressure-signal-selection-popup-respone';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-pressure-historical-creation-popup';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-pressure-historical-creation-popup.component.html',
  styleUrl: './ne-configuration-pressure-historical-creation-popup.component.scss',
})
export class NeConfigurationPressureHistoricalCreationPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  isLoading = false;
  canSave = true;

  popupSettings: PressureSelectionPopupSettings;
  formSettings: HistoricalFormSettings = {};
  currentConfigurations: HierarchyElementPressureSignalDto[] = [];
  historicalConfigurations: HierarchyElementPressureSignalVersionDto[] = [];
  datesFromModel: HierarchyElementPressureSignalVersionDto;
  formModel: HierarchyElementPressureSignalVersionDto;
  validTo: Date;
  validFrom: Date;
  initialFormModel: HierarchyElementPressureSignalVersionDto;

  configuredSignals: SignalInfoDto[] = [];

  constructor(
    private dialogRef: MatDialogRef<NeConfigurationPressureHistoricalCreationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) popupSettings: PressureSelectionPopupSettings,
    private _utilsService: UtilsHelperService
  ) {
    this.popupSettings = popupSettings;
  }

  ngOnInit(): void {
    this.formSettings = new HistoricalFormSettings({
      minDate: this.popupSettings.minDate,
      maxDate: this.popupSettings.maxDate,
      editOnlyDates: false,
      emitAtFormInitialization: true,
    });

    this.initialFormModel = new HierarchyElementPressureSignalVersionDto({
      validFrom: this.popupSettings.minDate,
      validTo: this.popupSettings.maxDate,
      signalId: null,
      pointId: null,
      pointDescription: null,
      id: null,
      hierarchyElementId: null,
      pressureTypeId: null,
      offset: null,
      offsetCoordinates: null,
      hierarchyElementTypeId: null,
      title: '',
    });
    this.datesFromModel = { ...this.initialFormModel };
  }

  close() {
    this.dialogRef.close();
  }

  onLoadingChanged(isLoading) {
    this.isLoading = isLoading;
  }

  saveConfiguration(): void {
    this.buildHistoricalConfigurations();
    const popupResponse = new PressureSignalSelectionPopupResponse({
      configurations: this.historicalConfigurations,
    });
    this.dialogRef.close(popupResponse);
  }

  onHistoricalFormChanges(data: HierarchyElementPressureSignalVersionDto): void {
    this.validFrom = data.validFrom;
    this.validTo = data.validTo;
  }

  onPressureCurrentConfigChanges(data: PressureSignalConfigurationDto): void {
    this.currentConfigurations = [...data.signals];
  }

  private buildHistoricalConfigurations(): void {
    if (this.isValid) {
      this.historicalConfigurations = this.currentConfigurations.map(
        (currentConfig) =>
          new HierarchyElementPressureSignalVersionDto({
            ...currentConfig,
            validFrom: this.validFrom,
            validTo: this.validTo,
            title: '',
            id: globalUtilsHelper.generateGuid(),
            offset: currentConfig.offset,
            offsetCoordinates: currentConfig.offsetCoordinates,
            hierarchyElementTypeId: this.popupSettings.networkElement.hierarchyElementTypeId,
          })
      );
    }
  }

  get isValid(): boolean {
    return this.currentConfigurations?.length && !!this.validFrom && !!this.validTo;
  }
}
