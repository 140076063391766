import { Component, DestroyRef, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subscription } from 'rxjs';
import { WlmResizeObserverService } from 'src/app/common-modules/shared/services/resize-observer.service';
import { BaseMapComponent } from '../../../map/base-map/base-map.component';
import { MapParameters } from '../../../map/map-parameters';

@Component({
  selector: 'wlm-integration-gis-layers-map',
  templateUrl: './integration-gis-layers-map.component.html',
  styleUrls: ['./integration-gis-layers-map.component.scss'],
})
export class IntegrationGisLayersMapComponent implements OnInit {
  @Input() activeStatus$: Observable<boolean>;

  private _geojsonFeatures: GeoJSON.FeatureCollection;
  get geojsonFeatures(): GeoJSON.FeatureCollection {
    return this._geojsonFeatures;
  }
  @Input() set geojsonFeatures(value: GeoJSON.FeatureCollection) {
    this._geojsonFeatures = value;
    if (this._geojsonFeatures) {
      this.setMapParams();
    }
  }

  private _selectedNetworkTypeIds: number[];
  get selectedNetworkTypeIds(): number[] {
    return this._selectedNetworkTypeIds;
  }
  @Input() set selectedNetworkTypeIds(value: number[]) {
    this._selectedNetworkTypeIds = value;
    this.setMapParams();
  }

  @ViewChild('map') set setMapComponent(map: BaseMapComponent) {
    this._map = map;
    if (map) {
      this._activeStatusSubs?.unsubscribe();
      this._activeStatusSubs = this.activeStatus$
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe((isActive) => {
          if (isActive) {
            map.resize();
          }
        });
    }
  }

  @ViewChild('mapContainer') set setmapContainer(mapContainer: ElementRef<any>) {
    if (mapContainer) {
      this._resizeContainerSubs?.unsubscribe();
      this._resizeContainerSubs = this._resizeObserverService
        .observe({
          el: mapContainer.nativeElement,
        })
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe(() => {
          this._map?.resize();
        });
    }
  }

  private readonly _resizeObserverService = inject(WlmResizeObserverService);
  private readonly _destroyRef = inject(DestroyRef);
  mapParams: MapParameters;
  private readonly _settingArea = 'MapSetting';
  private readonly _settingKey = `Integration_MapFilter`;
  private _activeStatusSubs: Subscription;
  private _resizeContainerSubs: Subscription;
  private _map;

  ngOnInit(): void {
    const mapParams = this.initializeMapParameters();
    this.mapParams = mapParams;
  }

  private initializeMapParameters() {
    const mapParameters = MapParameters.getparameter({
      hierarchyElements: [],
      networkElementTypes: this.selectedNetworkTypeIds ?? [],
      leakYears: null,
      visibleThematicsIds: null,
      showFilters: true,
      settingArea: this._settingArea,
      settingKey: this._settingKey,
      loadFromPersistency: false, // in map widgets we do not want to load from bd
      geojsonFeatures: this.geojsonFeatures,
    });

    return mapParameters;
  }

  setMapParams() {
    if (this.geojsonFeatures && this.selectedNetworkTypeIds) {
      const mapParams = this.initializeMapParameters();
      this.mapParams = mapParams;
    }
  }

  clear(): void {
    this.selectedNetworkTypeIds = [];
    this.geojsonFeatures = this.buildEmptyFeaturesCollection();
    const mapParams = this.initializeMapParameters();
    this.mapParams = mapParams;
  }

  private buildEmptyFeaturesCollection = (): GeoJSON.FeatureCollection => ({
    type: 'FeatureCollection',
    features: [],
  });
}
