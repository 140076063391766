// prettier-ignore
import { ChangeDetectorRef, DestroyRef, Directive, ElementRef, EventEmitter, inject, Injector, Input, NgZone, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
// prettier-ignore
import { ColumnComponent, ColumnLockedChangeEvent, ColumnReorderEvent, GridComponent, GridDataResult, RowClassArgs, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
// prettier-ignore
import { CompositeFilterDescriptor, DataResult, FilterDescriptor, isCompositeFilterDescriptor, SortDescriptor, State } from '@progress/kendo-data-query';
import dayjs from 'dayjs';
import { BehaviorSubject, fromEvent, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { ApplicationAttributes } from 'src/app/common-modules/shared/constants/application-constants';
import { NotificationSelectionService } from 'src/app/common-modules/shared/notifications/notification-selection.service';
import { AdditionalHttpOpts } from '../../cache/http-cache/additional-http-options';
import { IHttpCacheResponse } from '../../cache/http-cache/http-cache-response';
import { HttpCacheService } from '../../cache/http-cache/http-cache.service';
import { TabDetailPanelParameters } from '../../dependencies/navigation/tab-detail-component';
import { BaseComponent } from '../../shared/component/base.component';
import { GridSetting } from '../../shared/constants/grid.constants';
import { IExportPdfComponent } from '../../shared/exports/models/export-pdf-component';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { GridHelperService } from '../../shared/helpers/grid-helper.service';
import { ObjectHelperService } from '../../shared/helpers/object-helper.service';
import { LocalStorageService } from '../../shared/local-storage.service';
import { GridColumnSetting } from '../../shared/model/grid/grid-column-setting';
import { GlobalsService } from '../../shared/services/globals.service';
import { WlmResizeObserverService } from '../../shared/services/resize-observer.service';
import { GenericChartService } from '../../wlm-charts/core/services/generic-chart.service';
import { SpinnerService } from '../../wlm-spinner/spinner.service';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizeService } from '../../shared/auth/services/authorize.service';
import { SettingsService } from '../../shared/config/settings.service';
import { DateFormatsService } from '../../shared/localization/date-formats.service';
import { GridPersistedElements } from '../generic-grid/generic-grid-constants';
import { CellEditedInfoDto } from '../model/cell-edited-info.dto';
import { TimeControlBarDto } from '../model/time-control-bar.dto';
import { GridEditionService } from '../services/grid-edition.service';
import { GridExportHelperService } from '../services/grid-export-helper.service';

@UntilDestroy()
@Directive()
export abstract class BaseGridComponent extends BaseComponent implements IExportPdfComponent {
  private _grid: GridComponent;
  get grid(): GridComponent {
    return this._grid;
  }
  @ViewChild(GridComponent) set grid(value: GridComponent) {
    this._grid = value;
    if (this.grid) {
      this.gridReady.emit();
    }
  }
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  private _gridContainerRef: ElementRef;
  get gridContainerRef(): ElementRef {
    return this._gridContainerRef;
  }
  @ViewChild('gridContainer') set gridContainerRef(value: ElementRef) {
    this._gridContainerRef = value;
    if (value) {
      this.listenResize(this.gridContainerRef);
      this.listenScroll(this.gridContainerRef.nativeElement);
    }
  }

  @Output() selectedItemChanged = new EventEmitter<any>();
  @Output() selectedItemsChange = new EventEmitter<any[]>();
  @Output() selectedPersistedItemsChanged = new EventEmitter<void>();
  @Output() editedValuesChange = new EventEmitter<Map<any, Map<string, CellEditedInfoDto>>>();
  @Output() gridDataLoaded = new EventEmitter<void>();
  @Output() notifyUseLatestCalculationDay = new EventEmitter<boolean>();
  @Output() timeControlChange = new EventEmitter<TimeControlBarDto>();
  @Output() gridReady = new EventEmitter<void>();
  @Output() isInThePastChange = new EventEmitter<boolean>();

  @Input() useCellStyle = false;
  @Input() subscriptionTag = 'default';
  @Input() persistencyArea: string;
  @Input() usePersistence = true;
  @Input() showSelectedList = true;
  @Input() showSelectedItems = true;
  @Input() storageLocation: 'session' | 'local' = 'local';
  @Input() showTooltipOnRows = false;
  @Input() addHttpOptions: AdditionalHttpOpts = { showSpinner: true };
  @Input() initialSelectedKeys: string[];
  @Input() cacheInstanceId: string;
  @Input() entityPermission: string;

  @Input() conditionalSorting: SortDescriptor[];

  private _applyConditionalSorting = false;
  public get applyConditionalSorting() {
    return this._applyConditionalSorting;
  }
  @Input() public set applyConditionalSorting(value) {
    if (value != this.applyConditionalSorting) {
      this._applyConditionalSorting = value;
      this._cd.detectChanges();
    }
  }

  private _disablePager: boolean;
  public get disablePager(): boolean {
    return this._disablePager;
  }
  @Input() public set disablePager(value: boolean) {
    this._disablePager = value;

    this.grid.pageable = value ? false : this.gridSettings.pageable;
  }

  protected _clickedRow: any;
  protected _fitColumnsSubscription = new Subscription();
  gridSelectionCacheDuration = 1440;
  private get useLocalStorage(): boolean {
    return this.storageLocation === 'local';
  }

  private get getSessionId(): string {
    return this.storage.getRawValue('session_state', false);
  }

  private get sessionEnding(): string {
    return this.useLocalStorage ? '' : `/${this.getSessionId}`;
  }

  private get getSelectionPersistencyUrl(): string {
    return `http://local/grid/selected/${this.persistencyArea}${this.sessionEnding}`;
  }

  defaultFamilyFieldName = 'hierarchyFamilyId';
  currencySymbol: string;
  selectedRows: any[] = [];

  currentSelection: string[] = [];
  totalCount: number;
  initialColumnFilter: CompositeFilterDescriptor;
  initialPageSize: number;

  visibleColumns: GridColumnSetting[] = [];
  excludeFromExportColumnTypes: string[] = [];
  backgroundColorMapping: Map<any, string> = new Map<any, string>();
  exportableColumns: GridColumnSetting[];
  isProcessingSelectAll = false;

  sort: SortDescriptor[];
  alwaysPersistedKeys: string[] = [GridPersistedElements.Pagesize];
  defaultPagesize = 10;
  filter: CompositeFilterDescriptor;
  skip: number;
  clearSelectionPersistedInProcess = false;
  selectAllIsIndeterminate = false;
  selectAllValue = false;
  cellEditedValue: any;
  previousCellClickHandlerParams: any;

  private _timeControlDates: TimeControlBarDto;
  public get timeControlDates(): TimeControlBarDto {
    return this._timeControlDates;
  }
  public set timeControlDates(value: TimeControlBarDto) {
    this._timeControlDates = value;
    this._cd.detectChanges();
    this.comparisonIsActive = !!value?.compareDate;
  }
  initialControlDates: TimeControlBarDto;
  comparisonIsActive: boolean = false;
  useLatestCalculationDay: boolean = true;

  public state: State;

  protected globalsService: GlobalsService;
  protected gridHelperService: GridHelperService;
  protected ngZone: NgZone;
  protected storage: LocalStorageService;
  protected httpCacheService: HttpCacheService;
  protected objectHelperService: ObjectHelperService;
  private _notificationSelectionService: NotificationSelectionService;
  protected _spinnerService: SpinnerService;
  protected _objectHelperService: ObjectHelperService;
  protected _dateFormatsService: DateFormatsService;
  protected _settingsService: SettingsService;
  protected _cd: ChangeDetectorRef;
  private _authorizeService: AuthorizeService;
  private _dialogService: MatDialog;

  public abstract gridSettings: GridSetting;

  public initializingSubject$: Observable<boolean>;
  public loadingHandler$ = new BehaviorSubject<boolean>(false);
  public genericChartService: GenericChartService;
  public formGroup: UntypedFormGroup = new UntypedFormGroup({});

  selectAllState: SelectAllCheckboxState = 'unchecked';

  private _selectedItems: any[];
  public get selectedItems(): any[] {
    return this._selectedItems;
  }
  @Input()
  public set selectedItems(value: any[]) {
    if (value) {
      this._selectedItems = value;
    }
  }

  private _columns: GridColumnSetting[];
  public get columns(): GridColumnSetting[] {
    return this._columns;
  }
  public set columns(v: GridColumnSetting[]) {
    this._columns = v;
    this.setExportableColumns();
    this.setVisibleColumns();
  }

  private _resizeObserverService: WlmResizeObserverService;
  private readonly _columnEditionWidth = 250;
  private _scrollableEventSubs: Subscription;
  private _scrollLeft: number;
  private _scrollableElement: HTMLElement;
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _scrollableContainerClass = '.k-grid-content';
  private readonly _gridExportHelperService = inject(GridExportHelperService);

  constructor(protected _injector: Injector, private _gridEditionService: GridEditionService) {
    super();
    this.genericChartService = this._injector.get(GenericChartService);
    this.globalsService = this._injector.get(GlobalsService);
    this.gridHelperService = this._injector.get(GridHelperService);
    this.ngZone = this._injector.get(NgZone);
    this.storage = this._injector.get(LocalStorageService);
    this.httpCacheService = this._injector.get(HttpCacheService);
    this.objectHelperService = this._injector.get(ObjectHelperService);
    this._notificationSelectionService = this._injector.get(NotificationSelectionService);
    this._resizeObserverService = this._injector.get(WlmResizeObserverService);
    this._spinnerService = this._injector.get(SpinnerService);
    this._objectHelperService = this._injector.get(ObjectHelperService);
    this._dateFormatsService = this._injector.get(DateFormatsService);
    this._settingsService = this._injector.get(SettingsService);
    this._cd = this._injector.get(ChangeDetectorRef);
    this._authorizeService = this._injector.get(AuthorizeService);
    this._dialogService = this._injector.get(MatDialog);
  }

  abstract initColumns();
  abstract reloadGrid();
  abstract setTotalCount(count: number): void;
  abstract clearFilters(): void;
  abstract canClearFilters();

  initializeState() {
    this.state = {
      skip: this.skip ?? 0,
      take: this.gridSettings.pageSize,
      sort: this.sort,

      // Initial filter descriptor
      filter: this.initialColumnFilter,
    };
  }

  setGridFilters() {
    const persistedFilter = this.getPersistedElement(
      GridPersistedElements.Filter,
      null,
      this.useLocalStorage
    );

    if (persistedFilter !== null) {
      const normalizedFilters = this.normalizeFilter(persistedFilter);
      this.applyColumnFilter(normalizedFilters);
    } else {
      if (this.initialColumnFilter) {
        this.setPersistedElement(
          GridPersistedElements.Filter,
          this.initialColumnFilter,
          this.useLocalStorage
        );
      }
    }
  }

  removePersistency(persistedElements: GridPersistedElements[]) {
    if (this.persistencyArea && persistedElements?.length) {
      persistedElements.forEach((pe) =>
        this.storage.remove(`${this.persistencyArea}-${pe}`, this.useLocalStorage)
      );
    }
  }

  rowCallback = (context: RowClassArgs) => {
    if (!this._clickedRow) {
      return {
        rowclicked: false,
      };
    }

    const clickedRow = context?.dataItem;
    const selectField = this.gridSettings?.selectByFieldName;

    if (selectField) {
      return {
        rowclicked: clickedRow[selectField] === this._clickedRow[selectField],
      };
    }

    const diffObject = this.objectHelperService.deepDiff(clickedRow, this._clickedRow);
    const isEqual = Object.keys(diffObject).length === 0;

    return {
      rowclicked: isEqual,
    };
  };

  onSelectedItemsChanged(items: any[]) {
    this.selectedItems = items;
    this.currentSelection = items.map((x) => x[this.gridSettings.selectByFieldName]) ?? [];
    this.selectedRows = items;
    this.persistSelectedRows();
    this.calculateSelectAllState();
    if (!items?.length) {
      this._clickedRow = null;
    }

    this.selectedItemsChange.emit(this.selectedItems);
  }

  selectedKeysChange(rows: string[]) {
    const selected = this.selectedRows.filter((x) =>
      rows.includes(x[this.gridSettings?.selectByFieldName])
    );

    const gridResult = (this.grid?.data as GridDataResult)?.data;

    const selectedFromGrid = gridResult?.filter((x) =>
      rows.includes(x[this.gridSettings?.selectByFieldName])
    );

    // This is to exclude repeated elements
    selectedFromGrid?.forEach((x) => {
      if (
        !selected.some(
          (y) => y[this.gridSettings?.selectByFieldName] === x[this.gridSettings?.selectByFieldName]
        )
      ) {
        selected.push(x);
      }
    });

    this.selectedRows = selected;
    if (this.grid) {
      this.calculateSelectAllState();

      if (!this.isProcessingSelectAll) {
        this.persistSelectedRows();
        this.selectedItemsChange.emit(this.selectedRows);
      }
    } else {
      this.selectedItemsChange.emit(this.selectedRows);
    }
  }

  calculateSelectAllState() {
    const gridResult = (this.grid?.data as GridDataResult)?.data.map(
      (x) => x[this.gridSettings?.selectByFieldName]
    );

    const value =
      this.currentSelection.length === 0
        ? 'unchecked'
        : !gridResult.every((x) => this.currentSelection.includes(x))
        ? 'indeterminate'
        : 'checked';
    this.selectAllState = value;
    this.selectAllIsIndeterminate = value === 'indeterminate';
    this.selectAllValue = value === 'checked';
  }

  persistSelectedRows(selectedRows?: any[]) {
    if (
      this.gridSettings?.selectionPersistency &&
      this.persistencyArea &&
      this.persistencyArea !== null
    ) {
      const selectionToPersist = selectedRows ?? this.selectedRows;

      const r: IHttpCacheResponse = {
        url: this.getSelectionPersistencyUrl,
        body: selectionToPersist,
        lastModified: null,
        creationDate: dayjs().toDate(),
        expirationDate: dayjs().add(this.gridSelectionCacheDuration, 'minute').toDate(),
        reload: false,
        instanceId: this.cacheInstanceId,
      };

      // Save selection in cache
      this.httpCacheService.put(r);
    }
  }

  setSelectedRows() {
    if (
      this.gridSettings?.selectionPersistency &&
      !this.isProcessingSelectAll &&
      !this.clearSelectionPersistedInProcess
    ) {
      this.httpCacheService.getById(this.getSelectionPersistencyUrl).then((selectionPersisted) => {
        if (selectionPersisted) {
          this.selectedRows = selectionPersisted.body;
          this.currentSelection = this.selectedRows.map(
            (x) => x[this.gridSettings.selectByFieldName]
          );
          this.calculateSelectAllState();
          this.selectedItemsChange.emit(this.selectedRows);
          this.selectedPersistedItemsChanged.emit();
        }
      });
    }
    this.calculateSelectAllState();
  }

  cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!dataItem) {
      return;
    }

    this.previousCellClickHandlerParams = { sender, rowIndex, columnIndex, dataItem, isEdited };
    const columnField = (this.grid.columnList.toArray()[columnIndex] as ColumnComponent).field;

    const columnInfo = this.gridSettings.gridColumnSettings.find((f) => f.field === columnField);
    const cellValue = dataItem[columnField];
    const cellHasValue = cellValue !== null && cellValue !== undefined;

    const column = sender.columnList.columns._results[columnIndex];

    if (!isEdited && this.columnIsEditable(columnInfo) && cellHasValue) {
      this.hasEditPermission().subscribe((allowed) => {
        if (allowed) {
          this.formGroup = this.createFormGroup(dataItem, columnInfo.field);
          column.width = this._columnEditionWidth;
          sender.editCell(rowIndex, columnIndex, this.formGroup);
        }
      });
    }

    if (this._clickedRow === dataItem) {
      return;
    }
    this._clickedRow = dataItem;

    if (this._clickedRow) {
      this.selectedItemChanged.emit(globalUtilsHelper.clone(this._clickedRow, true));

      // the gridsetting must be clone to avoid modify the original gridsetting object
      const gridSetting = this.objectHelperService.clone(this.gridSettings);
      if (this.gridSettings.notificationSetting?.isNotificable) {
        this._notificationSelectionService.setNotificationDetailsFromGrid(
          this._clickedRow,
          gridSetting
        );
      }
    }
  }

  private columnIsEditable(columnInfo: GridColumnSetting): boolean {
    const isEditable = columnInfo?.editable === true;
    return isEditable;
  }

  private hasEditPermission(): Observable<boolean> {
    let permission$ = of(true);
    if (this.entityPermission) {
      permission$ = this._authorizeService.canAccess(this.entityPermission, 'u');
    }
    return permission$;
  }

  createFormGroup = (dataItem: any, field: string) => {
    let group = {};
    group[field] = new UntypedFormControl(dataItem[field]);

    return new UntypedFormGroup(group);
  };

  cellCloseHandler(event: any) {
    const previousDataItem = this._objectHelperService.clone(event.dataItem);
    const field = event.column.field;

    const newDataItem = this._objectHelperService.clone(previousDataItem);

    const idField = this.gridSettings.selectByFieldName;
    const rowIndex = (this.grid.data as DataResult).data.findIndex(
      (x) => x[idField] == event.dataItem[idField]
    );

    if (newDataItem[field] != this.cellEditedValue) {
      this._gridEditionService.registerCellChange(
        idField,
        previousDataItem,
        field,
        this.cellEditedValue
      );

      newDataItem[field] = this.cellEditedValue;

      (this.grid.data as DataResult).data[rowIndex] = newDataItem;
    }
    this.editedValuesChange.emit(this._gridEditionService.getCurrentChanges());
    this.fitColumns();
  }

  onCellEditionChange(value: any) {
    this.cellEditedValue = value;
  }

  setCurrencySymbol = () => {
    this.globalsService
      .getApplicationAttributesById(ApplicationAttributes.Currency)
      .subscribe((attribute) => {
        this.currencySymbol = attribute.attributeValue;
        this._cd.detectChanges();
      });
  };

  filterChange(filter: CompositeFilterDescriptor) {
    this.setPersistedElement(GridPersistedElements.Filter, filter, this.useLocalStorage);
    this.clearRowSelected();
    this.fitColumns();
  }

  sortChange(sort: SortDescriptor[]) {
    this.setPersistedElement(GridPersistedElements.Sort, sort, this.useLocalStorage);
    this.clearRowSelected();
    this.fitColumns();
  }

  persistGridSettings(settings: GridSetting) {
    this.setPersistedElement(GridPersistedElements.Settings, settings, this.useLocalStorage);
  }

  removePersistedGridSettings(): void {
    // Must reset all elements that can be edited in the manage columns.
    const elements = [GridPersistedElements.Settings, GridPersistedElements.Pagesize];

    for (const element of elements) {
      this.storage.remove(`${this.persistencyArea}-${element}`, this.useLocalStorage);
    }
  }

  clearRowSelected() {
    this._clickedRow = null;
    if (this.gridSettings?.notificationSetting?.isNotificable) {
      this._notificationSelectionService.cleanNotificationDetails();
    }

    this.selectedItemChanged.emit(null);
    this.checkPagesizeChange();
  }

  checkPagesizeChange(newPageSize?: number) {
    const pagesize = newPageSize ?? this.grid?.pageSize ?? this.defaultPagesize;
    if (this.grid && pagesize !== this.initialPageSize) {
      this.setPersistedElement(GridPersistedElements.Pagesize, pagesize, this.useLocalStorage);
      this.initialPageSize = pagesize;
      this.gridSettings.pageSize = pagesize;
    }
  }

  // Persist elements in [local|session]Storage if persistencyArea is defined
  setPersistedElement(key: string, element: any, useLocalStorage?: boolean) {
    if (this.persistencyArea) {
      this.storage.addOrUpdate(`${this.persistencyArea}-${key}`, element, useLocalStorage);
    }
  }

  // Get elements from [local|session]Storage if persistencyArea is defined and usePersistence is enabled
  getPersistedElement(key: string, defaultValue?: any, useLocalStorage?: boolean): any {
    const storageKey = `${this.persistencyArea}-${key}`;
    if (this.persistencyArea && (this.usePersistence || this.alwaysPersistedKeys.includes(key))) {
      return this.storage.getTyped(storageKey, defaultValue, useLocalStorage);
    } else {
      this.storage.remove(storageKey, useLocalStorage);
    }

    return defaultValue;
  }

  setGridSorting() {
    this.sort = this.getPersistedElement(
      GridPersistedElements.Sort,
      this.gridSettings.sort,
      this.useLocalStorage
    );
  }

  applyColumnFilter(initialFilters: FilterDescriptor[]) {
    if (initialFilters?.length > 0) {
      const columns = [...new Set(initialFilters.map((x) => x.field))];

      this.initialColumnFilter = { filters: [], logic: 'and' } as CompositeFilterDescriptor;
      columns.forEach((fieldName) => {
        const columnFilter: CompositeFilterDescriptor = {
          filters: initialFilters.filter((z) => z.field === fieldName),
          logic: 'or',
        };
        this.initialColumnFilter.filters.push(columnFilter);
      });

      this.initialColumnFilter.logic = 'and';
    }
  }

  clearSelectionPersisted() {
    this.clearSelectionPersistedInProcess = true;
    this.httpCacheService.clearContainsInUrl(this.getSelectionPersistencyUrl).then((clearKeys) => {
      this.clearSelectionPersistedInProcess = false;

      //Remove selection and emit
      this.selectAllState = 'unchecked';
      this.selectedRows = [];
      this.selectedItemsChange.emit(this.selectedRows);
    });
  }

  headerTextChanged() {
    this.fitColumns();
  }

  getUnitFormat(columnSetting: GridColumnSetting): string {
    if (!columnSetting.unitFormat || !columnSetting.isCurrencyFormat) {
      return undefined;
    }

    const format = columnSetting.unitFormat.replace('{1}', this.currencySymbol);
    return format;
  }

  fitColumns(): void {
    if (!this.gridSettings?.enableAutoResize) {
      return;
    }

    this._fitColumnsSubscription.unsubscribe();
    this._fitColumnsSubscription = this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.grid?.autoFitColumns();
        if (this._scrollLeft) {
          this._scrollableElement.scrollTo({
            left: this._scrollLeft,
          });
        } else {
          this.grid?.scrollTo({ row: 0 });
          this._scrollLeft = null;
        }
      });
  }

  onColumnLockedChange(event: ColumnLockedChangeEvent) {
    const columnLocked = event?.columns?.[0];

    if (columnLocked) {
      let columnSettingsCloned = this._objectHelperService.clone(
        this.gridSettings.gridColumnSettings
      );
      let gridColumn = columnSettingsCloned.filter((c) => c.field === columnLocked['field'])[0];

      // At this point, locked property is not updated yet
      gridColumn.locked = !columnLocked.locked;

      this.gridSettings.gridColumnSettings = columnSettingsCloned;
      this.persistGridSettings(this.gridSettings);
    }
  }

  onColumnReorderChange(event: ColumnReorderEvent) {
    let { column, newIndex, oldIndex } = event;

    if (!column || (newIndex && newIndex === oldIndex)) {
      return;
    }

    // Kendo includes SelectAll column when enabled but it is not part of GridSettings columns
    if (this.gridSettings.showSelectAllColumn) {
      newIndex--;
      oldIndex--;
    }

    let columnSettingsCloned = this._objectHelperService.clone(
      this.gridSettings.gridColumnSettings.filter((f) => f.visible)
    );
    let columnSettingsClonedHidden = this._objectHelperService.clone(
      this.gridSettings.gridColumnSettings.filter((f) => !f.visible)
    );

    var columnMoved = columnSettingsCloned[oldIndex];
    columnSettingsCloned.splice(oldIndex, 1);
    columnSettingsCloned.splice(newIndex, 0, columnMoved);

    this.gridSettings.gridColumnSettings = columnSettingsCloned.concat(columnSettingsClonedHidden);
    this.persistGridSettings(this.gridSettings);
  }

  onTimeControlDatesChange(timeControlBar: TimeControlBarDto) {
    // This property is needed as input for Kendo in order to read it in WlmGridBinding
    this.timeControlDates = timeControlBar;
    this.clearRowSelected();

    this.useLatestCalculationDay =
      this.initialControlDates.baseGridDate.toISOString() ===
      timeControlBar.baseGridDate.toISOString();
    this.notifyUseLatestCalculationDay.emit(this.useLatestCalculationDay);
    this.timeControlChange.emit(timeControlBar);
    this._cd.detectChanges();
  }

  onIsInThePastChange(isInThePast: boolean): void {
    this.isInThePastChange.emit(isInThePast);
  }

  exportToPdf(): void {
    this._gridExportHelperService.wrap(() => {
      this.grid.saveAsPDF();
    });
  }

  isEmpty(): boolean {
    return !this.grid || (this.grid?.data as any)?.total === 0;
  }

  getObjectToExport() {
    return this.grid;
  }

  showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    const parentElement = element.parentElement;

    if (
      element.nodeName === 'SPAN' &&
      element.className.split(' ').includes('ellipsis-text') &&
      parentElement.offsetWidth < element.offsetWidth + 31 &&
      element.innerText.length < this._settingsService.maxOverflowToShowExtendedTooltip
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  protected removeStorage(key: string) {
    const storageKey = `${this.persistencyArea}-${key}`;
    this.storage.remove(storageKey, this.useLocalStorage);
  }

  protected setGridPagesize() {
    const pageSize = this.gridSettings?.pageSize ?? this.defaultPagesize;
    const persistedPagesize = this.getPersistedElement(
      GridPersistedElements.Pagesize,
      pageSize,
      this.useLocalStorage
    );
    this.gridSettings.pageSize = persistedPagesize;
  }

  protected clearSelectAllSelection() {
    this.selectedItems = [];
    this.currentSelection = [];
    this._clickedRow = null;
    this.selectAllValue = false;
    this.selectAllIsIndeterminate = false;
  }

  protected setInitialPagesize() {
    this.initialPageSize = this.gridSettings?.pageSize ?? this.defaultPagesize;
  }

  private setExportableColumns() {
    // get columns that use a reference column
    const columnsWithReference = this.columns?.filter((column) => column?.referenceColumn?.length);

    if (columnsWithReference?.length > 0) {
      // updates the visibility of these columns with the visibility of its reference (referenceColumn)
      this.columns
        .filter((f) => columnsWithReference.includes(f))
        .forEach((column) => {
          column.visible = this.getReferenceColumnVisibility(column.referenceColumn);
        });
    }

    this.exportableColumns = this.columns?.filter(
      (column) =>
        column.visible &&
        !this.gridHelperService.getColumnTypesNonExportable().includes(column.type)
    );
  }

  private getReferenceColumnVisibility(referenceColumn: string): boolean {
    return this.columns?.find((f) => f.field === referenceColumn && f.type !== 'export-only')
      ?.visible;
  }

  private setVisibleColumns() {
    this.visibleColumns = this.columns?.filter((f) => f.visible);
  }

  private normalizeFilter(persistedFilter: any) {
    if (persistedFilter === null) {
      return persistedFilter;
    }

    const initialFilters = persistedFilter.filters
      .map((f) => (isCompositeFilterDescriptor(f) ? f.filters : [f]))
      .reduce((p, n) => p.concat(n), []);

    initialFilters.forEach((filter) => {
      if (
        this.gridSettings.gridColumnSettings.filter(
          (x) => x.field === filter.field && x.type === 'date'
        ).length > 0
      ) {
        filter.value = new Date(filter.value);
      }
    });
    return initialFilters;
  }

  private listenResize(elementRef: ElementRef): void {
    if (this.gridSettings?.enableAutoResize) {
      this._resizeObserverService
        .observe({
          el: elementRef.nativeElement,
        })
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.grid?.autoFitColumns();
        });
    }
  }

  private listenScroll(element: HTMLElement): void {
    this._scrollableElement = element.querySelector(this._scrollableContainerClass);
    if (this._scrollableElement) {
      this._scrollableEventSubs?.unsubscribe();
      this._scrollableEventSubs = fromEvent(this._scrollableElement, 'scroll')
        .pipe(
          takeUntilDestroyed(this._destroyRef),
          debounceTime(300),
          map(() => this._scrollableElement.scrollLeft),
          distinctUntilChanged()
        )
        .subscribe((scrollLeft: number) => {
          this._scrollLeft = scrollLeft;
        });
    }
  }

  protected setBackgroundColorMapping() {
    // if (this._gridSettings && this._gridSettings.backgroundColorMethodName) {
    //   this.gridHelperService
    //     .getBackgroundColorMapping(this.gridSettings?.backgroundColorMethodName)
    //     .subscribe({
    //       next: (colorsMapping) => {
    //         this.backgroundColorMapping = colorsMapping;
    //       },
    //     });
    // }
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}
}
