import { TypeOption } from '@ngx-formly/core/lib/models';
import { ChipsSelectorComponent } from './types/chips-selector/chips-selector.component';
import { ColorPickerTypeComponent } from './types/color-picker-type/color-picker-type.component';
import { FileUploadTypeComponent } from './types/file-upload-type/file-upload-type.component';
import { SwitcherTypeComponent } from './types/switcher-type/switcher-type.component';

export const configurableFormTypes: TypeOption[] = [
  {
    name: 'chips-selector',
    component: ChipsSelectorComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'color-picker',
    component: ColorPickerTypeComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'file-upload',
    component: FileUploadTypeComponent,
  },
  {
    name: 'switcher',
    component: SwitcherTypeComponent,
    wrappers: [],
  }
];
