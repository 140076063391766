<wlm-ne-configuration-historical-switcher
  [selectedNE]="currentNE"
  class="switcher"
  [widgetId]="widgetId"
  [pageId]="pageId"
>
  <ng-template #currentTemplate>
    <wlm-ne-configuration-large-user
      [selectedNE]="currentNE"
      class="full-size-historical-switcher"
      [pageId]="pageId"
      [widgetId]="widgetId"
    ></wlm-ne-configuration-large-user>
  </ng-template>
  <ng-template #historicalTemplate let-isReadOnly="isReadOnly">
    <wlm-ne-configuration-large-user-historical
      [selectedNE]="currentNE"
      class="historical-component"
      [widgetId]="widgetId"
      [pageId]="pageId"
      [isReadOnly]="isReadOnly"
    ></wlm-ne-configuration-large-user-historical>
  </ng-template>
</wlm-ne-configuration-historical-switcher>
