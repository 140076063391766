import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { PressureSettings } from 'src/app/common-modules/dependencies/ne-configuration/pressure-settings';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { CrudConstants } from 'src/app/common-modules/dependencies/shared/crud-constants';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { DragListCardSettings } from 'src/app/common-modules/shared/core/drag-list-card/drag-list-card-settings';
import { DragListCustomSettings } from 'src/app/common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { ValidationHelperService } from 'src/app/common-modules/shared/helpers/validation-helper.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { DimensionTypesEnum } from 'src/app/common-modules/shared/model/shared/dimension-types';
import { UnitTypeConversionViewDto } from 'src/app/common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { UoMService } from 'src/app/common-modules/shared/uom/uom.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { HierarchyElementPressureSignalDto } from 'src/app/water-loss/features/shared/model/signals/hierarchy-element-pressure-signal.dto';
import { PressureSignalConfigurationDto } from 'src/app/water-loss/features/shared/model/signals/pressure-signal-configuration.dto';
import { PressureSignalTypesEnum } from 'src/app/water-loss/features/shared/model/signals/pressure-signal-types.enum';
import { HierarchyElementPressureSaveDto } from '../../shared/model/signals/hierarchy-element-pressure-save.dto';
import { PressureSignalDto } from '../../shared/model/signals/pressure-signal.dto';
import { OffsetUnitService } from '../ne-configuration-pressure-historical/offset-unit.service';
import { NeConfigurationPressureService } from '../ne-configuration-pressure/ne-configuration-pressure.service';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-pressure-current-config';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configutation-pressure-current-config.component.html',
  styleUrls: ['./ne-configutation-pressure-current-config.component.scss'],
})
export class NeConfigurationPressureCurrentConfigComponent
  implements OnInit, IPendingChangesEmitter
{
  @Input() pageId: string;
  @Input() widgetId: string;
  @Input() pressureSettings: PressureSettings;
  @Input() disableButtons = false;
  @Input() disableInitialLoad = false;
  @Input() enableHistoricalService = false;
  @Output() formChanges = new EventEmitter<PressureSignalConfigurationDto>();

  private _selectedNE: INetworkElementDto;
  public get selectedNE(): INetworkElementDto {
    return this._selectedNE;
  }

  @Input() public set selectedNE(value: INetworkElementDto) {
    if (value) {
      this._selectedNE = value;
      this.updateQueryParams();

      if (!this.disableInitialLoad) {
        this.loadPressureConfiguration();
      } else {
        this.populatePressureData([]);
      }
    }
  }

  get componentName(): string {
    return 'NeConfigurationPressureCurrentConfigComponent';
  }

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  refreshList$ = new Subject<void>();
  reloadAvailableList$ = new Subject<void>();
  excludedPressureSignals: PressureSignalDto[] = [];
  includedPressureSignals: PressureSignalDto[] = [];
  queryParams: Map<string, any>;
  settings: DragListSettings;
  azpListSettings: DragListCustomSettings;
  cppListSettings: DragListCustomSettings;
  mppListSettings: DragListCustomSettings;
  cardSettings: DragListCardSettings;
  configuredAzp: HierarchyElementPressureSignalDto[] = [];
  configuredCpp: HierarchyElementPressureSignalDto[] = [];
  configuredMpp: HierarchyElementPressureSignalDto[] = [];
  pressureSaveDto: HierarchyElementPressureSaveDto;

  private _configurationHasChanged = false;
  public get configurationHasChanged() {
    return this._configurationHasChanged;
  }
  public set configurationHasChanged(value) {
    this._configurationHasChanged = value;
    this.setPendingChanges(this.pageId, this.getPendingChanges(value));
  }

  private _mppOffset: number;
  public get mppOffset(): number {
    return this._mppOffset;
  }
  public set mppOffset(value: number) {
    this._mppOffset = value;
    this.compareConfigurations();
  }
  private _cppOffset: number;
  public get cppOffset(): number {
    return this._cppOffset;
  }
  public set cppOffset(value: number) {
    this._cppOffset = value;
    this.compareConfigurations();
  }
  originalConfiguredAzp: HierarchyElementPressureSignalDto[] = [];
  originalConfiguredCpp: HierarchyElementPressureSignalDto[] = [];
  originalConfiguredMpp: HierarchyElementPressureSignalDto[] = [];
  originalMppOffset: number;
  originalCppOffset: number;
  disableCppOffset = false;
  disableMppOffset = false;
  isReadOnly = true;
  hasInvalidOffsetValue: boolean = false;
  offsetUnit: string;

  // this is necessary to access PressureSignalTypesEnum from html side
  signalType: typeof PressureSignalTypesEnum = PressureSignalTypesEnum;

  private _hierarchyElementIdParamName = 'hierarchyElementId';
  private _serviceName = 'NeConfigurationPressureService';
  private _historicalServiceName = 'AvailableSignalsVersionService';
  private _pagesize = 50;
  private _titleFieldName = 'title';
  private _pointIdFieldName = 'pointId';
  private _pointDescriptionFieldName = 'pointDescription';
  private _dimensionTypeIdFieldName = 'dimensionTypeId';
  private _isPointOfInterestFieldName = 'isPointOfInterest';
  private _pressureDimensionTypeId = DimensionTypesEnum.Pressure;
  private _pointsODataFilters: Map<string, any>;
  private _pressureConversion: UnitTypeConversionViewDto;
  private _dimensionTypeId = 2;
  private _timeAggregationId = 2;

  constructor(
    private readonly _spinnerService: SpinnerService,
    private readonly _pressureService: NeConfigurationPressureService,
    private readonly _objectHelperService: ObjectHelperService,
    private readonly _dialogService: DialogService,
    public customValidators: ValidationHelperService,
    private readonly _authorizeService: AuthorizeService,
    private readonly _uomService: UoMService,
    private readonly _pendingChangesService: PendingChangesManagerService,
    private readonly _offsetUnitService: OffsetUnitService
  ) {}

  ngOnInit(): void {
    this.checkReadOnlyAccess();
    this.prepareListsSettings();
  }

  init(): void {}
  mapInitParameters(parameters: TabDetailPanelParameters) {}

  checkReadOnlyAccess() {
    this._authorizeService
      .canAccess(CrudConstants.NetworkElementCrud, 'u')
      .subscribe((canAccess) => {
        this.isReadOnly = !canAccess;
      });
  }

  prepareListsSettings() {
    this.prepareODataFilters();
    const dataService = this.enableHistoricalService
      ? this._historicalServiceName
      : this._serviceName;

    this.settings = new DragListSettings({
      dataService: dataService,
      pageSize: this._pagesize,
      useQueryParams: true,
      displayFieldName: this._titleFieldName,
      orderBy: [{ field: this._pointDescriptionFieldName, dir: 'asc' }],
      cacheOptions: { avoid: true },
      oDataFilters: this._pointsODataFilters,
      scrollId: this.widgetId,
    });

    const baseSettingsSingleList = new DragListCustomSettings({
      hideFilter: true,
      isReadOnly: this.isReadOnly,
      useSingleContainer: true,
    });

    this.azpListSettings = {
      ...baseSettingsSingleList,
      allowDropCallback: this.allowDropAzp,
    };

    this.cppListSettings = {
      ...baseSettingsSingleList,
      allowDropCallback: this.allowDropCpp,
    };

    this.mppListSettings = {
      ...baseSettingsSingleList,
      allowDropCallback: this.allowDropMpp,
    };

    this.cardSettings = new DragListCardSettings({
      fields: [this._pointIdFieldName, this._pointDescriptionFieldName],
      fieldLabels: {
        pointDescription: this._pointDescriptionFieldName,
        pointId: this._pointIdFieldName,
      },
      iconName: 'card-handler',
      isSvg: true,
      isReadOnly: this.isReadOnly,
    });
  }

  allowDropAzp = (dropData) => {
    return this.configuredAzp.length === 0;
  };

  allowDropCpp = (dropData) => {
    return !this.cppOffset && this.configuredCpp.length === 0;
  };

  allowDropMpp = (dropData) => {
    return !this.mppOffset && this.configuredMpp.length === 0;
  };

  loadPressureConfiguration() {
    this._spinnerService.setLoading(true, this.componentName);
    const pressureConfiguration$ = this._pressureService.getPressureConfigurationByZone(
      this.selectedNE.hierarchyElementId
    );
    const uomConversions$ = this._offsetUnitService.getOffsetUnit(
      this.selectedNE.hierarchyElementTypeId
    );

    combineLatest([pressureConfiguration$, uomConversions$]).subscribe(
      ([pressureConfiguration, uomConversions]) => {
        this.setConversionData(uomConversions);

        if (pressureConfiguration) {
          this.populatePressureData(pressureConfiguration);
        }
      }
    );
  }

  private populatePressureData(pressureConfiguration: HierarchyElementPressureSignalDto[]) {
    this.loadConfiguredPoints(pressureConfiguration);

    this.loadConfiguredOffsets(pressureConfiguration);

    this.checkOffSetState();

    this.originalConfiguredAzp = this._objectHelperService.clone(this.configuredAzp);
    this.originalConfiguredCpp = this._objectHelperService.clone(this.configuredCpp);
    this.originalConfiguredMpp = this._objectHelperService.clone(this.configuredMpp);
    this.originalCppOffset = this._objectHelperService.clone(this.cppOffset);
    this.originalMppOffset = this._objectHelperService.clone(this.mppOffset);

    this.configurationHasChanged = false;
    this.excludedPressureSignals = [];
    this._spinnerService.setLoading(false, this.componentName);
  }

  private setConversionData(pressureConversion: UnitTypeConversionViewDto) {
    this._pressureConversion = pressureConversion;

    if (!this._pressureConversion) {
      console.error(
        `There is no pressure conversion for heTypeId: ${
          this.selectedNE.hierarchyElementTypeId
        } and timeAggregationId: ${this._offsetUnitService.getPressureSignalTimeAggregationUnit()}`
      );
    }

    this.offsetUnit = this._pressureConversion
      ? `[${this._pressureConversion.unitTypeToDescription}]`
      : '';

    this._offsetUnitService.setOffsetUnit(this.offsetUnit);
  }

  private loadConfiguredOffsets(pressureConfiguration: HierarchyElementPressureSignalDto[]) {
    this.cppOffset = this.getConfiguredOffset(pressureConfiguration, PressureSignalTypesEnum.CPP);

    this.mppOffset = this.getConfiguredOffset(pressureConfiguration, PressureSignalTypesEnum.MPP);
  }

  private loadConfiguredPoints(pressureConfiguration: HierarchyElementPressureSignalDto[]) {
    this.configuredAzp = this.getPressureSignalFiltered(
      pressureConfiguration,
      PressureSignalTypesEnum.AZP
    );
    this.configuredCpp = this.getPressureSignalFiltered(
      pressureConfiguration,
      PressureSignalTypesEnum.CPP
    );
    this.configuredMpp = this.getPressureSignalFiltered(
      pressureConfiguration,
      PressureSignalTypesEnum.MPP
    );
  }

  getConfiguredOffset(
    pressureConfiguration: HierarchyElementPressureSignalDto[],
    pressureType: PressureSignalTypesEnum
  ): number {
    const configuredPoint = pressureConfiguration.find(
      (x) => x.pressureTypeId === pressureType && x.offset
    );

    return configuredPoint ? this.convertFromUom(configuredPoint.offset) : null;
  }

  convertFromUom(value: number): number {
    if (!value || !this._pressureConversion) {
      return value;
    }

    return +value * this._pressureConversion?.conversionFactor;
  }

  convertToUom(value: number): number {
    if (!value || !this._pressureConversion || this._pressureConversion?.conversionFactor == 0) {
      return value;
    }

    return +value / this._pressureConversion?.conversionFactor;
  }

  getPressureSignalFiltered(
    pressureConfiguration: HierarchyElementPressureSignalDto[],
    typeId: PressureSignalTypesEnum
  ): HierarchyElementPressureSignalDto[] {
    let filteredSignal: HierarchyElementPressureSignalDto;

    if (typeId === PressureSignalTypesEnum.AZP) {
      // In case of multiple signals for the same pressure type, we take the first occurrence
      filteredSignal = pressureConfiguration.find((x) => x.pressureTypeId === typeId);
    } else {
      filteredSignal = pressureConfiguration.find((x) => x.pressureTypeId === typeId && !x.offset);
    }

    return filteredSignal ? [filteredSignal] : [];
  }

  updateQueryParams() {
    const newParams = new Map<string, any>();
    newParams.set(this._hierarchyElementIdParamName, this.selectedNE?.elementId);
    this.queryParams = newParams;
  }

  onSave() {
    this.save().subscribe(() => {});
  }

  save(networkElement?: INetworkElementDto): Observable<boolean> {
    this.setSpinner(true);
    networkElement = networkElement === undefined ? this.selectedNE : networkElement;

    const signals = this.prepareSignalsList();

    const newConfiguration = new PressureSignalConfigurationDto({
      hierarchyElementId: networkElement.hierarchyElementId,
      signals: signals,
    });

    return this._pressureService.savePressureConfiguration(newConfiguration).pipe(
      tap((result) => {
        if (!result) {
          const messageKey = 'common.messages.saved-error';
          this.displayMessage(messageKey, 'error');
          return;
        } else {
          this._dialogService.showEntityActionSnackBar('save', 'configuration');
        }
        this._spinnerService.setLoading(false, this.componentName);
        this.loadPressureConfiguration();
        this.reloadAvailableList$.next();
      }),
      catchError((error) => {
        this.setSpinner(false);
        const messageKey = 'common.messages.saved-error';
        this.displayMessage(messageKey, 'error');

        return of(null);
      }),
      map((_) => true)
    );
  }

  private prepareSignalsList() {
    const finalConfiguration = [...this.configuredAzp];
    const cppConf = this.processConfiguration(
      this.configuredCpp,
      PressureSignalTypesEnum.CPP,
      this.cppOffset
    );
    const mppConf = this.processConfiguration(
      this.configuredMpp,
      PressureSignalTypesEnum.MPP,
      this.mppOffset
    );

    if (cppConf) {
      finalConfiguration.push(cppConf);
    }

    if (mppConf) {
      finalConfiguration.push(mppConf);
    }
    return finalConfiguration;
  }

  private displayMessage(messageKey: string, icon: 'success' | 'error', isSnackbar = true) {
    const dialogSettings = new WlmDialogSettings({ translateKey: messageKey });
    dialogSettings.icon = icon;

    if (isSnackbar) {
      this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
    } else {
      this._dialogService.showTranslatedMessage(dialogSettings);
    }
  }

  private setSpinner(isLoading: boolean) {
    this._spinnerService.setLoading(isLoading, this.componentName);
  }

  processConfiguration(
    configuredPoint: HierarchyElementPressureSignalDto[],
    typeId: PressureSignalTypesEnum,
    offset: number
  ) {
    if (offset) {
      const currentAzp = this.configuredAzp[0];

      const azpCopy = this._objectHelperService.clone(currentAzp, true);
      azpCopy.offset = this.convertToUom(offset);
      azpCopy.pressureTypeId = typeId; //<-- clone issue: this set also modifies the original

      return azpCopy;
    }

    return configuredPoint.length ? configuredPoint[0] : null;
  }

  discard() {
    this.configuredAzp = this._objectHelperService.clone(this.originalConfiguredAzp);
    this.configuredCpp = this._objectHelperService.clone(this.originalConfiguredCpp);
    this.configuredMpp = this._objectHelperService.clone(this.originalConfiguredMpp);

    this.cppOffset = this._objectHelperService.clone(this.originalCppOffset);
    this.mppOffset = this._objectHelperService.clone(this.originalMppOffset);

    this.excludedPressureSignals = [];
    this.reloadAvailableList$.next();

    this.configurationHasChanged = false;
    this.checkOffSetState();
  }

  onDroppedElement(signal: PressureSignalDto, element: PressureSignalTypesEnum) {
    const newSignalConfigured = new HierarchyElementPressureSignalDto({
      hierarchyElementId: this.selectedNE.hierarchyElementId,
      pointDescription: signal.pointDescription,
      pointId: signal.pointId,
      signalId: signal.signalId,
      pressureTypeId: element,
      title: `${signal.pointId} - ${signal.pointDescription}`,
    });
    if (element === PressureSignalTypesEnum.AZP) {
      this.setList(signal, this.configuredAzp);
      this.configuredAzp = [newSignalConfigured];
    }

    if (element === PressureSignalTypesEnum.CPP) {
      this.setList(signal, this.configuredCpp);
      this.configuredCpp = [newSignalConfigured];
    }

    if (element === PressureSignalTypesEnum.MPP) {
      this.setList(signal, this.configuredMpp);
      this.configuredMpp = [newSignalConfigured];
    }

    this.checkOffSetState();
    this.compareConfigurations();
    this.emitFormChangesIfNeeded();
  }

  emitFormChangesIfNeeded() {
    const signals = this.prepareSignalsList();
    const newConfiguration = new PressureSignalConfigurationDto({
      hierarchyElementId: this.selectedNE.hierarchyElementId,
      signals: signals,
    });

    this.formChanges.emit(newConfiguration);
  }

  checkOffSetState() {
    const hasAzp = this.configuredAzp.length > 0;
    const hasCpp = this.configuredCpp.length > 0;
    const hasMpp = this.configuredMpp.length > 0;

    this.disableCppOffset = !hasAzp || hasCpp;
    this.disableMppOffset = !hasAzp || hasMpp;

    if (!hasAzp) {
      this.cppOffset = null;
      this.mppOffset = null;
    }
  }

  setList(signal: PressureSignalDto, configuredList: HierarchyElementPressureSignalDto[]) {
    const previousElement = configuredList.find((x) => x.signalId != signal.signalId);
    if (previousElement) {
      const isCurrentlyExcluded = this.excludedPressureSignals
        .map((x) => x.signalId)
        .includes(previousElement.signalId);

      if (isCurrentlyExcluded) {
        this.excludedPressureSignals = this.excludedPressureSignals.filter(
          (x) => x.signalId != previousElement.signalId
        );
      }
    }

    const newExcludedList = this._objectHelperService.clone(this.excludedPressureSignals);
    newExcludedList.push(signal);
    this.excludedPressureSignals = newExcludedList;
  }

  onAvailableSomethingChanged() {
    this.checkOffSetState();
    this.compareConfigurations();
    this.emitFormChangesIfNeeded();
  }

  compareConfigurations() {
    if (this.compareLists()) {
      this.configurationHasChanged = true;
      return;
    }

    if (this.compareOffsets()) {
      this.configurationHasChanged = true;
      return;
    }

    this.configurationHasChanged = false;
  }

  compareOffsets() {
    const cppOffsetChanged = +this.cppOffset !== +this.originalCppOffset;
    const mppOffsetChanged = +this.mppOffset !== +this.originalMppOffset;

    const regex = new RegExp(/([\-|0|\.]){1}$/g);

    this.hasInvalidOffsetValue =
      regex.test(this.cppOffset?.toString()) || regex.test(this.mppOffset?.toString());

    return cppOffsetChanged || mppOffsetChanged;
  }

  compareLists(): boolean {
    const configuredLists = [this.configuredAzp, this.configuredCpp, this.configuredMpp];
    const originalLists = [
      this.originalConfiguredAzp,
      this.originalConfiguredCpp,
      this.originalConfiguredMpp,
    ];

    for (var i = 0; i < configuredLists.length; i++) {
      const result = this.listHasChanged(
        configuredLists[i],
        originalLists[i],
        this._pointIdFieldName
      );
      if (result) {
        return true;
      }
    }

    return false;
  }

  listHasChanged(current: any[], original: any[], field: string) {
    if (current.length !== original.length) {
      return true;
    }

    for (let i = 0, l = current.length; i < l; i++) {
      if (current[i][field] !== original[i][field]) {
        return true;
      }
    }

    return false;
  }

  onOffsetChanged(): void {
    this.emitFormChangesIfNeeded();
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private prepareODataFilters() {
    this._pointsODataFilters = new Map<string, any>();

    this._pointsODataFilters.set(
      this._dimensionTypeIdFieldName,
      this._pressureDimensionTypeId.valueOf()
    );

    this._pointsODataFilters.set(this._isPointOfInterestFieldName, true);
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: hasChanges && !this.hasInvalidOffsetValue,
      saveFn: () => this.save(),
    };
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
