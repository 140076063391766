import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UnitTypeConversionViewDto } from 'src/app/common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from 'src/app/common-modules/shared/uom/uom.service';

@Injectable({
  providedIn: 'root',
})
export class OffsetUnitService {
  private _dimensionTypeId = 2;
  private _timeAggregationId = 2;

  constructor(private _uomService: UoMService) {}
  private offsetUnitSubject = new BehaviorSubject<string>('');
  offsetUnit$ = this.offsetUnitSubject.asObservable();

  setOffsetUnit(unit: string) {
    this.offsetUnitSubject.next(unit);
  }

  getOffsetUnit(hierarchyElementTypeId: string): Observable<UnitTypeConversionViewDto> {
    return this._uomService.getByParams(
      this._dimensionTypeId,
      this._timeAggregationId,
      hierarchyElementTypeId
    );
  }

  getPressureSignalTimeAggregationUnit() {
    return this._timeAggregationId;
  }
}
