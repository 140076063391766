import { Component, inject, Input, OnInit } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { INetworkElementTypeDto } from 'src/app/common-modules/dependencies/ne/network-element-type.dto';
import {
  IntegrableForm,
  IntegrableFormParams,
} from 'src/app/common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { NETypesCrudService } from '../../ne-types/ne-types-crud.service';
import { IntegrationGisLayersDto } from '../integration-gis-layers.dto';

@Component({
  selector: 'wlm-integration-gis-layers-form',
  templateUrl: './integration-gis-layers-form.component.html',
  styleUrls: ['./integration-gis-layers-form.component.scss'],
})
export class IntegrationGisLayersFormComponent extends IntegrableForm implements OnInit {
  @Input() initialModel: IntegrationGisLayersDto;
  model: IntegrationGisLayersDto;

  private readonly _neTypesCrudService = inject(NETypesCrudService);
  private readonly _globalsService = inject(GlobalsService);
  selectedNetworkElementTypeId$ = new BehaviorSubject<number>(0);
  selectedGisLayerId$ = new BehaviorSubject<number>(0);
  networkElementTypeOptions$ = new BehaviorSubject<INetworkElementTypeDto[]>([]);
  networkElementSubTypeOptions$ = new BehaviorSubject<any[]>([]);

  ngOnInit(): void {
    this._neTypesCrudService.getAll().subscribe((options) => {
      this.networkElementTypeOptions$.next(options);
    });
    this._globalsService.getNetworkElementSubTypes().subscribe((options) => {
      this.networkElementSubTypeOptions$.next(options);
    });
  }

  onModelChange(model: IntegrationGisLayersDto): void {
    this.model = globalUtilsHelper.clone(model, true);
    this.model.layersFile = model.layersFile;
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'layersFile',
          type: 'file-upload',
          className: 'col-12',
          props: {
            label: 'GIS Layers (json)',
            required: true,
          },
        },
        {
          key: 'networkElementTypeId',
          type: 'select',
          className: 'col-12',
          props: {
            label: 'Network Element Type',
            required: true,
            valueProp: 'networkElementTypeId',
            labelProp: 'networkElementTypeName',
            options: this.networkElementTypeOptions$.asObservable(),
            change: (field, $event) => {
              const selectedId = $event.value;

              const selectedOption = this.networkElementTypeOptions$
                .getValue()
                .find((option) => option.networkElementTypeId === selectedId);

              if (selectedOption) {
                this.selectedNetworkElementTypeId$.next(selectedOption.gisLayerId);
                this.selectedGisLayerId$.next(selectedOption.gisLayerId);
              }
            },
          },
        },
        {
          key: 'networkElementSubTypeId',
          type: 'select',
          className: 'col-12',
          props: {
            label: 'Network Element Sub Type',
            valueProp: 'networkElementSubTypeId',
            labelProp: 'networkElementSubTypeName',
            options: this.selectedNetworkElementTypeId$.pipe(
              switchMap((networkElementTypeId) =>
                this.networkElementSubTypeOptions$.pipe(
                  map((subTypes) =>
                    subTypes.filter(
                      (subType) => subType.networkElementTypeId === networkElementTypeId
                    )
                  )
                )
              )
            ),
            change: (field, $event) => {
              const selectedId = $event.value;
              field.formControl.setValue(selectedId);

              this.networkElementSubTypeOptions$
                .pipe(
                  map((subTypes) =>
                    subTypes.find((subType) => subType.networkElementSubTypeId === selectedId)
                  )
                )
                .subscribe((selectedOption) => {
                  if (selectedOption) {
                    this.selectedGisLayerId$.next(selectedOption.gisLayerId);
                  }
                });
            },
          },
          hooks: {
            onInit: (field) => {
              (field.props.options as Observable<any[]>).subscribe((options) => {
                if (!options || options.length === 0) {
                  field.formControl.setValue(undefined);
                  field.formControl.disable();
                } else {
                  field.formControl.enable();
                }
              });
            },
          },
        },
        {
          key: 'gisLayerId',
          type: 'input',
          hide: true,
          props: {
            label: 'GIS Layer Id',
            required: true,
          },
          hooks: {
            onInit: (field) => {
              this.selectedGisLayerId$.subscribe((gisLayerId) => {
                field.formControl.setValue(gisLayerId);
              });
            },
          },
        },
      ],
    },
  ];
}
