<wlm-ne-configuration-historical-switcher
  [selectedNE]="selectedNE"
  class="switcher"
  [widgetId]="widgetId"
  [pageId]="pageId"
>
  <ng-template #currentTemplate>
    <wlm-ne-configuration-pressure-current-config
      class="item-container"
      [selectedNE]="selectedNE"
      [widgetId]="widgetId"
      [pageId]="pageId"
      [pressureSettings]="pressureSettingsOtp"
    ></wlm-ne-configuration-pressure-current-config>
  </ng-template>
  <ng-template #historicalTemplate let-isReadOnly="isReadOnly">
    <wlm-ne-configuration-pressure-historical
      class="item-container"
      [selectedNE]="selectedNE"
      [widgetId]="widgetId"
      [pageId]="pageId"
      [pressureSettings]="pressureSettingsOtp"
      [isReadOnly]="isReadOnly"
    ></wlm-ne-configuration-pressure-historical>
  </ng-template>
</wlm-ne-configuration-historical-switcher>
