// prettier-ignore
import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SubscriptSizing } from '@angular/material/form-field';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, Subject, combineLatest, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { BaseFilterItemSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { BaseFilterSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-settings';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { LocalStorageService } from 'src/app/common-modules/shared/local-storage.service';
import { BaseFilterItemComponent } from '../base-filter-item/base-filter-item.component';

const COMPONENT_SELECTOR = 'wlm-base-filter';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './base-filter.component.html',
  styleUrls: ['./base-filter.component.scss'],
})
export class BaseFilterComponent implements OnInit, AfterViewInit {
  // Main config of the component.
  private _settings: BaseFilterSettings;
  get settings(): BaseFilterSettings {
    return this._settings;
  }
  @Input() set settings(value: BaseFilterSettings) {
    this._settings = value;
    if (this.settings?.disableFilter) {
      this.filterCtrl.disable();
    } else {
      this.filterCtrl.enable();
    }
  }
  // Notifies the component when to clear all filters.
  @Input() clearAll$ = new Subject<void>();
  // Notifies the component when to trigger the "apply" event from an external source.
  @Input() apply$ = new Subject<void>();
  // Notifies the component when to trigger the "persist" event from an external source.
  @Input() persistItems$ = new Subject<void>();
  @Input() filtersContainerMaxHeight: string;
  // Receives the children base filter items.
  @ContentChildren(BaseFilterItemComponent) filterItems: BaseFilterItemComponent[];
  // Contains the template with the summary and the base filter items that will be opened in the popup.
  @ViewChild('filterContainer') filterContainerRef: TemplateRef<any>;
  // Contains a refernce to the menu trigger.
  @ViewChild('filterMenuTrigger') filterMenuTrigger: MatMenuTrigger;
  // Emits the filter text for the children to consume.
  @Output() filterText = new EventEmitter<string>();
  // Emits the main filtered observable with the collection of filters.
  @Output() filter = new EventEmitter<FiltersPayload>();
  // Emits the current filters on demand.
  @Output() apply = new EventEmitter<FiltersPayload>();
  // Emits if the filters are all valid or not.
  @Output() valid = new EventEmitter<boolean>();
  // Emits if the clear all event is emitted.
  @Output() clearAll = new EventEmitter<void>();
  // Mimics the main events, but with a subject. Useful for coordinating Base Filters like Base Filter Items.
  filteredCoordinateOutput$ = new ReplaySubject<FiltersPayload>(1);
  applyCoordinateOutput$ = new ReplaySubject<FiltersPayload>(1);
  // The main templates of all the children.
  templates: TemplateRef<any>[];
  // The templates that show the summaries of each filter.
  templateSummaries: TemplateRef<any>[];
  // Handles the state of the main input.
  filterCtrl = new UntypedFormControl();
  // The summary of the filter input.
  inputSummary: string;
  // The last emitted filters.
  filters: FiltersPayload = new FiltersPayload({});
  // Observes if the menu is closed.
  menuIsOpen = false;
  // Checks that all the active filters are valid. If not, do not emit filters.
  areAllValid = false;
  // Used to save the filter initial values
  initialFilters: FiltersPayload = new FiltersPayload({});
  // Flag to check if apply button has been pressed
  filtersApplied = false;
  // The min length of characters for the filters to start searching.
  minSearchChars = 3;
  readonly subscriptSizing: SubscriptSizing = 'dynamic';

  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;

  constructor(
    private localStorageService: LocalStorageService,
    private objectHelper: ObjectHelperService
  ) {}

  ngOnInit(): void {
    this.filterCtrl.valueChanges
      .pipe(
        tap(() => {
          if (this.settings.disableErrorStyle) {
            this.filterCtrl.setErrors(null);
          }
        }),
        distinctUntilChanged((x, y) => x === y),
        debounceTime(this.settings?.inputDebounceTime ?? 500),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        // Do not filter if the input is the summary.
        if (!value || (value.length >= this.minSearchChars && value !== this.inputSummary)) {
          this.filterText.emit(value);
        }
      });

    if (this.clearAll$) {
      this.clearAll$.pipe(untilDestroyed(this)).subscribe(() => this.onClearAll());
    }

    if (this.apply$) {
      this.apply$.pipe(untilDestroyed(this)).subscribe(() => this.onApplyFilters());
    }

    if (this.persistItems$) {
      this.persistItems$.pipe(untilDestroyed(this)).subscribe(() => this.persistFilters());
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getAllTemplates();
      this.propagateSettings();
      this.listenToAllFiltered();
    }, 0);
  }

  getAllTemplates(): void {
    this.templates = this.filterItems.map((item) => item.getTemplate());
    this.templateSummaries = this.filterItems.map((item) => item.getTemplateSummary());
  }

  /**
   * Propagate settings from the base filter to the base filter items.
   * Currently only the 'formMode' setting is propagated.
   */
  propagateSettings(): void {
    if (this.settings?.formMode) {
      this.filterItems.forEach((item) => {
        let newSettings = new BaseFilterItemSettings({});
        if (item.settings) {
          newSettings = { ...item.settings };
        }
        newSettings.formMode = this.settings.formMode;
        item.settings = newSettings;
      });
    }
  }

  /**
   * Get all the observables that notify when each filter item is filtered, and combine the in a single observable.
   */
  listenToAllFiltered(): void {
    // Do not consider hidden items.
    const activeItems = this.getActiveItems();
    if (activeItems.length === 0) {
      return;
    }
    // combineLatest: When any observable emits a value, emit the last emitted value from each.
    // This will mean we will get all the filters every time a new filter is updated.
    const allFiltered$ = activeItems.map((item) => item.getFiltered$());
    const filtered$ = combineLatest(allFiltered$).pipe(
      // Remove nulls
      map((data) => data.filter((item) => item)),
      map((data) => FiltersPayload.joinMany(data))
    );

    filtered$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.areAllValid = this.checkAllValid();
      this.valid.emit(this.areAllValid);
      // If values are not valid, the filter is emitted as empty.
      this.filters = this.areAllValid ? data : new FiltersPayload({});
      this.emitFilter();
      this.buildInputSummary();
    });

    this.applyHookCallback('fhAfterParentListening');
  }

  emitFilter(): void {
    this.filter.next(this.filters);
    this.filteredCoordinateOutput$.next(this.filters);
  }

  /**
   * Propagates the "Clear All" event to all the children.
   */
  onClearAll(): void {
    this.filterItems.forEach((item) => item.setClear());
    this.inputSummary = null;
    this.buildInputSummary();
    this.setInputValue(this.inputSummary);
    this.persistFilters();
    this.clearAll.emit();
  }

  /**
   * Propagates the "Select All" event to all the children.
   */
  onSelectAll(): void {
    this.filterItems.forEach((item) => item.setSelectAll());
    this.buildInputSummary();
  }

  /**
   * If the control has the input summary when we want to filter, clear it.
   */
  onMenuOpen(): void {
    this.menuIsOpen = true;
    this.filtersApplied = false;
    this.setInputValue(null);
    // Apply hook callback.
    this.applyHookCallback('fhAfterMenuOpen');
    this.initialFilters = null;
    this.initialFilters = this.filters.clone();
    this.saveInitialState();
    this.areAllValid = this.checkAllValid();
  }

  onFormFieldClick(inputRef) {
    if (this.settings?.disableFilter) {
      inputRef.stopPropagation();
      return;
    }

    setTimeout(() => {
      inputRef.focus();
    });
  }

  /**
   * If we finished writing in the input, show the summary again.
   */
  onMenuClose(): void {
    this.menuIsOpen = false;
    if (this.inputSummary) {
      this.setInputValue(this.inputSummary);
    }
    // Apply hook callback.
    this.applyHookCallback('fhAfterMenuClose');

    // If using the filter in normal mode, we must restore the state if the filters are not applied.
    // But in form mode, we must never restore the state in this case.
    if (
      !this.settings?.formMode &&
      !this.filtersApplied &&
      !this.objectHelper.deepEqualMaps(this.initialFilters.data, this.filters.data)
    ) {
      this.restoreState();
      this.initialFilters = this.filters;
    }
  }

  /**
   * Callback of the "Apply filters" button.
   */
  onApplyFilters(): void {
    this.filtersApplied = true;
    this.apply.next(this.filters);
    this.applyCoordinateOutput$.next(this.filters);
    if (this.filterMenuTrigger) {
      this.triggerMenuClose();
    }
    this.persistFilters();
  }

  /**
   * Programmatically close the menu.
   */
  triggerMenuClose(): void {
    this.filterMenuTrigger.closeMenu();
  }

  private persistFilters() {
    const activeItems = this.getActiveItems();
    if (this.filters.data.size > 0) {
      this.filters.data.forEach((filter) => {
        const key = `${this.settings?.persistencyArea}-${filter.fieldName}`;
        const item = activeItems.find((x) => x.getFieldNames().includes(filter.fieldName));
        const storage = item?.settings?.storageLocation;
        if (storage !== 'none') {
          this.localStorageService.addOrUpdate(key, filter, storage === 'local');
        }
      });
    } else {
      if (activeItems) {
        activeItems.forEach((filterItem) => {
          filterItem.getFieldNames().forEach((field) => {
            const key = `${this.settings?.persistencyArea}-${field}`;
            if (filterItem.settings) {
              this.localStorageService.remove(key, filterItem.settings.storageLocation === 'local');
            }
          });
        });
      }
    }
  }

  /**
   * Notifies all children to restore to the initial values
   */
  restoreState(): void {
    const filterItemsArray: BaseFilterItemComponent[] = [];
    this.filterItems.forEach((x) => filterItemsArray.push(x));
    filterItemsArray
      .sort((a, b) => (a.restoreOrder > b.restoreOrder ? 1 : -1))
      .forEach((item) => item.setRestoreState());

    this.inputSummary = null;
    this.buildInputSummary();
    this.setInputValue(this.inputSummary);
  }

  saveInitialState(): void {
    this.filterItems.forEach((item) => item.setInitialState());
  }

  /**
   * Retrieve the state of all the children and display it in the main filter placeholder.
   */
  buildInputSummary(): void {
    // Do not consider hidden items.
    const activeItems = this.getActiveItems();
    if (activeItems.length === 0) {
      return;
    }

    // If the filter is not valid, do not show a summary.
    if (!this.areAllValid) {
      this.inputSummary = '';
      this.setInputValue(this.inputSummary);
      return;
    }

    const states$ = this.filterItems.map((item) => item.getStateFormatted());
    forkJoin(states$)
      .pipe(untilDestroyed(this))
      .subscribe((states) => {
        states = states.filter((item) => item); // Remove nulls
        const formatted = states.join(`${this.settings?.inputSummarySeparator ?? ';'} `);
        this.inputSummary = formatted ?? '';

        if (!this.menuIsOpen) {
          this.setInputValue(this.inputSummary);
        }
      });
  }

  /**
   * Converts the array of filters to a Map, to be easier to use.
   */
  buildFiltersMap(filters: BasicFilter[]): FiltersPayload {
    const result = new FiltersPayload({});
    filters.forEach((filter) => {
      result.data.set(filter.fieldName, filter);
    });
    return result;
  }

  /**
   * Applies a function to all the filter items, only if they have declared it.
   */
  applyHookCallback(hookName: string): void {
    const items = this.getActiveItems();
    items.forEach((item: any) => {
      if (item[hookName]) {
        item[hookName]();
      }
    });
  }

  /**
   * Get only the filter items that are not disabled or hidden.
   */
  getActiveItems(): BaseFilterItemComponent[] {
    return this.filterItems?.filter((item) => !item.hide);
  }

  /**
   * Check if all active filters are valid.
   * Does not consider filters that are hidden.
   */
  checkAllValid(): boolean {
    const activeItems = this.getActiveItems();
    if (!activeItems) {
      return false;
    }
    const result = activeItems
      .map((item) => item.isValid())
      .reduce((accum, value) => accum && value);
    return result;
  }

  setInputValue(value: string): void {
    this.filterCtrl.setValue(value);
  }

  /**
   * Copies all the fields of the filters but sets all of their values to null.
   */
  buildEmptyFilters(inputFilters: FiltersPayload): FiltersPayload {
    const result = new FiltersPayload({});
    for (const [key, filter] of inputFilters.data) {
      filter.value = null;
      result.data.set(key, filter);
    }
    return result;
  }

  /**
   * Prevents any event from propagating.
   */
  stopPropagation($event): void {
    $event.stopPropagation();
  }
}
