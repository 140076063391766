import { Component, Input, inject } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { map } from 'rxjs';
import { ISapLeakCodeDto } from 'src/app/common-modules/dependencies/alc/sap-leak-code.dto';
import { ColumnEnumsService } from 'src/app/common-modules/shared/core/grid/column-enums.service';
import { IntegrableForm, IntegrableFormParams } from 'src/app/common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { SapLeakCodeCrudService } from '../sap-leak-code-crud.service';

@Component({
  selector: 'wlm-sap-leak-code-form',
  templateUrl: './sap-leak-code-form.component.html',
  styleUrls: ['./sap-leak-code-form.component.scss']
})
export class SapLeakCodeFormComponent extends IntegrableForm {
  @Input() initialModel: ISapLeakCodeDto;
  model: ISapLeakCodeDto;
  private readonly _sapLeakCodeCrudService = inject(SapLeakCodeCrudService);
  private readonly _columnEnumsService = inject(ColumnEnumsService);

  onModelChange(model: ISapLeakCodeDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'sapLeakCode',
          type: 'input',
          className: 'col-6',
          props: {
            label: 'Sap Leak Code',
            required: true,
          },
          expressions: {
            'props.disabled': (data: FormlyFieldConfig) =>
              this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'description',
          type: 'input',
          className: 'col-6',
          props: {
            label: 'Description',
            required: true,
          },
        },
        {
          key: 'leakageTypeId',
          type: 'select',
          className: 'col-6',
          props: {
            label: 'Leakage Type',
            valueProp: 'leakageTypeId',
            labelProp: 'leakageTypeName',
            required: true,
            options: this._columnEnumsService.getLeakageTypeMapping().pipe(map((mapa: Map<any, string>) => {
              return Array.from(mapa, ([key, value]) => ({ leakageTypeId: key, leakageTypeName: value }));
            })),
          },

        }
      ],
    },
  ];

}
