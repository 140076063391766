import { Injectable } from "@angular/core";

import { Observable, forkJoin, map, of } from "rxjs";
import { AppModules } from "src/app/common-modules/shared/app-modules.enum";
import { DialogService } from "src/app/common-modules/shared/dialogs/dialogs.service";
import { DateHelperService } from "src/app/common-modules/shared/helpers/date-helper.service";
import { DateFormats } from "src/app/common-modules/shared/localization/date-formats.enum";
import { LocalizationHelperService } from "src/app/common-modules/shared/localization/localization-helper.service";
import { LarsAndSworpsSiteSignalVersionsRelatedErrorDto } from "../../shared/model/signals/lars-and-sworps-site-signal-versions-related-error.dto";

@Injectable()
export class LarsSworpsCurrentMessagesService {
    T_SCOPE = `${AppModules.Configuration}.lars-sworps-messages-service`;
    private _configTitleMsg = `${this.T_SCOPE}.error-title`;
    private _configValuesMsg = `${this.T_SCOPE}.validation.values`;
    private _currentDatesMsg = 'common.current-dates';

    constructor(
        private _localizationService: LocalizationHelperService,
        private _dateHelper: DateHelperService,
        private _dialogService: DialogService
    ) { }

    showValidationErrorsPopup(
        errors: Partial<LarsAndSworpsSiteSignalVersionsRelatedErrorDto>[]
    ): void {
        const ts$ = [this._configTitleMsg, this._configValuesMsg, this._currentDatesMsg].map((msg) =>
            this._localizationService.get(msg)
        );

        forkJoin(ts$).subscribe(([titleTs, valuesTs, currentDatesTs]) => {
            let finalMessage = titleTs + '\n';
            const errorMessages$ = [];

            errors.forEach((error) => {
                const { pointDescription, networkElementName, validFrom, validTo, isLars, isSubtraction } =
                    error;

                const pointDescriptionTs = pointDescription
                    ? this.getPopupErrorField('point-description', { pointDescription })
                    : of(null);
                const networkElementNameTs = networkElementName
                    ? this.getPopupErrorField('element-name', { networkElementName })
                    : of(null);
                const validFromTs = validFrom
                    ? this.getPopupErrorField(`valid-from`, {
                        validFrom: this.formatDate(validFrom, currentDatesTs),
                    })
                    : of(null);
                const validToTs = validTo
                    ? this.getPopupErrorField(`valid-to`, {
                        validTo: this.formatDate(validTo, currentDatesTs),
                    })
                    : of(null);

                const configurationTypeTs = this.getPopupErrorField('configuration-type', {
                    larsConfiguration: isLars ? valuesTs.lars : valuesTs.sworps,
                    subtractionConfiguration: isSubtraction ? valuesTs.substraction : valuesTs.addition,
                });
                const errorMessage$ = forkJoin([
                    pointDescriptionTs,
                    networkElementNameTs,
                    validFromTs,
                    validToTs,
                    configurationTypeTs,
                ]).pipe(map((items) => items.filter(Boolean).join(', ')));

                errorMessages$.push(errorMessage$);
            });

            forkJoin(errorMessages$).subscribe((messages) => {
                finalMessage += messages.join('\n');

                this._dialogService.showMessage(finalMessage, 'error', {
                    popup: 'cross-site-errors-popup',
                });
            });
        });
    }

    private getPopupErrorField(fieldKey: string, params: any): Observable<string> {
        return this._localizationService.get(`${this.T_SCOPE}.validation.fields.${fieldKey}`, params);
    }

    private formatDate(date: Date, currentDatesTs): string {
        if (this._dateHelper.isCurrentStartDate(date)) {
            return currentDatesTs.start;
        }

        if (this._dateHelper.isCurrentEndDate(date)) {
            return currentDatesTs.end;
        }

        return this._localizationService.formatDate(
            this._dateHelper.ensureDateObject(date),
            DateFormats.Date
        );
    }
}
